import { useEffect, useState } from 'react'
import '../../assets/Fonts/fonts.css'
import { StyleSheet } from '../../assets/styles'
import { BasicStats } from '../../interfaces/DataVisualization'
import { calculateTotal } from '../../views/Insights/utils'
import { BarChart, BarChartProps } from '../Charts/BarChart/BarChart'

interface Props {
    title: string
    subtext: string
    style?: object
    barChartProps: BarChartProps
}

export const NotificationCard: React.FC<Props> = ({title, subtext, style, barChartProps}) => {
    const [currentNotificationTotal, setCurrentNotificationTotal] = useState<number>(0)
    const [notificationAverage, setNotificationAverage] = useState<number>(0)

    useEffect(() => {
        setCurrentNotificationTotal(calculateTotal(barChartProps.data))
    },[barChartProps.data])

    useEffect(() => {
        const notificationCount = barChartProps.data.reduce((total: number, data) => {
            total += data.total
            return total
        },0)
        setNotificationAverage(notificationCount/barChartProps.data.length)
    },[barChartProps.data])
    
    function scrollLeft() {
        const graphWrapper = document.getElementById(`div${barChartProps.data.length}`)
        if(graphWrapper)
        graphWrapper.scrollTo({left: 0})
    }

    function scrollRight() {
        const graphWrapper = document.getElementById(`div${barChartProps.data.length}`)
        if(graphWrapper)
        graphWrapper.scrollTo({left: graphWrapper.scrollWidth})
    }

    return (
        <div style={{...style, ...styles.container}}>
            <div style={styles.titleWrapper}>
                <p style={styles.titleText} className="Font-Rubik">{title}</p>
            </div>
            <div style={styles.subtextWrapper}>
                <p style={styles.subtext} className="Font-Rubik">{subtext}</p>
            </div>
            {/* <div style={styles.totalWrapper}>
                <p style={styles.totalText} className="Font-Rubik">{currentNotificationTotal}</p>
            </div> */}
            <div style={{
                        display: 'flex',
                        // flexDirection: 'column',
                        width: '100%',
                        flexGrow: 1
                    }}></div>
            <div style={styles.scrollWrapper}>
                {
                    // only for the 24 hour scroll
                    barChartProps.data.length === 24 && 
                    <button 
                        onClick={scrollLeft}
                        style={styles.scrollButton}
                    >
                        <div style={styles.buttonIconWrapper}>
                            ◄
                        </div>
                    </button>
                }
                    <div 
                        style={{
                            justifyContent: barChartProps.data.length === 24 ? 'unset' : 'center',
                            // height: 88,
                            flexGrow: 1,
                            paddingBottom: barChartProps.data.length === 24 ? 20 : 0,
                            ...styles.graphWrapper
                        }} 
                        id={`div${barChartProps.data.length}`}
                        >
                        <BarChart
                            color={barChartProps.color}
                            barWidth={barChartProps.barWidth}
                            data={barChartProps.data}
                            height={88}
                            labels={barChartProps.labels}
                            maxValue={barChartProps.maxValue}
                            tooltipLabel={title}
                            tooltipPrefix={barChartProps.tooltipPrefix}
                            />
                    </div>
                    {
                        // Only for the 24 hour scroll
                        barChartProps.data.length === 24 && 
                        <button 
                            onClick={scrollRight}
                            style={styles.scrollButton}
                        >
                            <div style={styles.buttonIconWrapper}>
                                ►
                            </div>
                        </button>
                    }
                </div>
                </div>
    )
}

export const BasicStatsCard: React.FC<{data: BasicStats[], style?: StyleSheet}> = ({data, style}) => {
    return(
        <div style={{...styles.container, ...style}}>
            <p style={{marginBottom: 16, ...styles.titleText}} className="Font-Rubik">Notification Statistics</p>
            <p style={styles.cardBodyText} className="Font-Rubik">
                {/* Number of days in pilot: {data[0].numberOfDaysInPilot}<br/> */}
                Number of days worn: {data[0].numberOfDaysWorn}<br />
                Percentage of days worn: {data[0].percentOfDaysWorn}<br />
                {/* Number of notifications with response: {data[0].numberOfNotifsWithResponse}<br /> */}
                Number of sent notifications: {data[0].numberOfSentNotifs}<br />
                Percentage of notifications with response: {data[0].percentOfNotifsWithResponse}<br />
            </p>
        </div>
    )
}

const styles: StyleSheet = {
    container: {
        minWidth: 260,
        minHeight: 204,
        height: 'auto',
        borderRadius: 16,
        padding: 20,
        boxShadow: '0px 0.5px 1.75px rgba(0, 0, 0, 0.039), 0px 1.85px 6.25px rgba(0, 0, 0, 0.19)',
        display: 'flex',
        flexFlow: 'column',
        position: 'relative'
    },
    titleWrapper: {
        maxWidth: 250
    },
    titleText: {
        fontWeight: 400,
        fontSize: 16,
        color: '#5A5A5A',
    },
    subtextWrapper :{
        maxWidth: 250,
        marginTop: 12,
    },
    subtext :{
        fontWeight: 200,
        fontSize: 14
    },
    cardBodyText: {
        fontWeight: 400,
        fontSize: 12,
        color: '#000000',
        lineHeight: '20px'
    },
    totalWrapper: {
        display: 'flex',
        alignItems: 'center',
        height: 36,
        marginBottom: 24
    },
    totalText: {
        fontWeight: 500,
        fontSize: 40,
        color: '#1B1B1B',
    },
    totalArrow: {
        marginLeft: 11,
        width: 10,
        height: 6,
    },
    scrollWrapper: {
        display: 'flex',
    },
    scrollButton: {
        border: 'none',
        color: '#5A5A5A',
        padding: 2,
        margin: 4,
        backgroundColor: 'transparent',
        borderRadius: 3,
    },
    buttonIconWrapper: {
        backgroundColor: '#F7F7F7',
        height: '80%',
        display: 'flex',
        alignItems: 'center',
        padding: 2,
        borderRadius: 16
    },
    graphWrapper: {
        display: 'flex',
        width: '100%',
        overflow: 'auto',
        bottom: 0
    }
}