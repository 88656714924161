import { useEffect, useState } from "react";
import { ComboBox } from "./ComboBox";

interface Props {
    setSelfParticipant: (id: string) => void;
    participantSelection: any[];
    defaultId: string | null;
}

export default function SelfAdvocateSelection(props: Props) {
    const [selected, setSelected] = useState({label: "Select a participant", value: "None"});

    useEffect(() => {
        if(props.defaultId) {
            const selection = {label: "Select a participant", value: "None"}
            const userParticipant = props.participantSelection.find(participant => participant.id === props.defaultId)
            if(userParticipant) {
                selection.label = userParticipant.description
                selection.value = userParticipant.id
            }
            setSelected(selection)
        }
    },[props.defaultId])

    return (
        <>
            <ComboBox
                label={selected.label}
                options={[
                    ...props.participantSelection.map((participant) => {
                        return {
                            label: participant.description,
                            value: participant.id,
                        };
                    }),
                ]}
                callback={(opts) => {
                    props.setSelfParticipant(opts?.value || "")}}
                defaultValue={selected}
            />
        </>
    )
}