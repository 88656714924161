import { Button, TextField } from "@mui/material"
import { useState } from "react"
import { Link, useHistory, useParams } from "react-router-dom"
import { AcceptRejectModal } from "../../../components/Modals/AcceptRejectModal"
import { useOrganizationId, useSites } from "../../../redux/hooks"
import { updateSite } from "../../../redux/sites/sitesHelpers"

interface Params {
    id: string
}

export const EditSite: React.FC = () => {
    const history = useHistory();
    let { id } = useParams<Params>();
    const organizationId = useOrganizationId()
    const sites = useSites().allSites.data
    const site = sites.find(site => site.id === id)
    const [name, setName] = useState(site?.name)
    const [code, setCode] = useState(site?.code)

    if(!site) history.push('/admin/sites')
    
    const handleChange = (field: string, value: string) => {
        switch(field) {
            case 'name':
                setName(value);
                return;
            case 'code':
                setCode(value);
                return;
        }
    }

    const handleSubmit = () => {
        if(!name || !code ) return;

        site &&
        updateSite({
            id: site.id,
            organizationId: organizationId,
            name: name,
            code: code
        })
        history.push('/admin/sites')
    }

    return (
        <div className="form-page-wrapper">
            <div className='form-wrapper'>
                <h2 className="form-header">Edit Site</h2>
                <p className="form-description">Fill out the fields below and click 'Edit', or click 'Cancel' to go back.</p>
                    <TextField
                        required
                        label="Name"
                        value={name}
                        sx={{marginTop: '0.5em', marginBottom: '0.5em'}}
                        onChange={(e) => handleChange('name', e.target.value)}
                    />
                    <TextField
                        required
                        label="Code"
                        value={code}
                        sx={{marginTop: '0.5em', marginBottom: '0.5em'}}
                        onChange={(e) => handleChange('code', e.target.value)}
                    />
                    <div className="form-buttons-wrapper">
                        <Link to={'#'} onClick={() => history.goBack()}>
                            <Button 
                                variant="contained"
                            >
                                Cancel
                            </Button>
                        </Link>   
                        <Link to={'#'}>
                            <AcceptRejectModal
                                openLabel='Edit'
                                openDisabled={false}
                                acceptLabel='Yes'
                                acceptColor='red'
                                rejectLabel='No'
                                message='Are you sure you want to edit this record?'
                                callBack={handleSubmit}
                            />
                        </Link>
                    </div>
            </div>
        </div>
    )
}