import { configureStore } from '@reduxjs/toolkit';
import tokensReducer from './tokens/tokensSlice';
import reportsReducer from './reports/reportsSlice';
import usersReducer from './users/usersSlice';
import participantsReducer from './participants/participantsSlice';
import careTeamsReducer from './careTeams/careTeamsSlice';
import organizationsReducer from './organizations/organizationsSlice';
import componentsReducer from './components/componentsSlice';
import notificationsReducer from './notifications/notificationsSlice';
import sitesReducer from './sites/sitesSlice';
import wearablesReducer from './wearables/wearablesSlice';
import userRolesReducer from './userRoles/userRolesSlice';
import errorsReducer from './errors/errorsSlice';
import visualizationsReducer from './dataVisualizations/dataVisualizationsSlice';
import analyticsReducer from './analytics/analyticsSlice';
import pairingReducer from './pairing/pairingSlice';

export const store = configureStore({
    reducer: {
        tokens: tokensReducer,
        reports: reportsReducer,
        users: usersReducer,
        participants: participantsReducer,
        careTeams: careTeamsReducer,
        organizations: organizationsReducer,
        components: componentsReducer,
        notifications: notificationsReducer,
        sites: sitesReducer,
        wearables: wearablesReducer,
        userRoles: userRolesReducer,
        errors: errorsReducer,
        visualizations: visualizationsReducer,
        analytics: analyticsReducer,
        pairing: pairingReducer,
    }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch