import { createSlice } from '@reduxjs/toolkit'
import { DefaultState, ChartTypes } from './interfaces'

const initialState: DefaultState = {
    charts: {
        selected: ChartTypes[0]
    }
};

const componentsSlice = createSlice({
    name: 'components',
    initialState,
    reducers: {
        _setChartType(state, action) {
            state.charts.selected = action.payload
        },
    }
});

export const { _setChartType } = componentsSlice.actions;

export default componentsSlice.reducer;