import { Button, Input, TextField, TextFieldProps } from "@mui/material";
import { useState } from "react";
import { BasicModal } from "./BasicModal";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

interface ResetParticipantModalProps {
  callBack: Function;
  message: string;
  openLabel: string;
  openDisabled?: boolean;
  openColor?: string;
  acceptLabel: string;
  rejectLabel: string;
  acceptColor?: string;
  rejectColor?: string;
}

export const ResetParticipantModal: React.FC<ResetParticipantModalProps> = (Props: ResetParticipantModalProps) => {
  const { callBack, message, openLabel, openDisabled, openColor, acceptLabel, rejectLabel, acceptColor, rejectColor } =
    Props;

  const [open, setOpen] = useState(false);
  const [activeTo, setActiveTo] = useState<string | null>(new Date().toDateString());

  const handleAccept = () => {
    callBack(activeTo);
    setOpen(false);
  };

  const handleReject = () => {
    setOpen(false);
  };

  const parseDate = (date: object) => {
    let timestamp = new Date(date.toString());
    let y = timestamp.getFullYear();
    let m = timestamp.getMonth() + 1;
    let d = timestamp.getDate();
    let h = timestamp.getHours();
    let min = timestamp.getMinutes();
    let s = timestamp.getSeconds();
    let ms = timestamp.getMilliseconds();
    let formatted = `${y}-${m}-${d} ${h}:${min}:${s}.${ms}`;
    return formatted;
  };
  const handleChange = (value: object) => {
    setActiveTo(parseDate(value));
  };

  const modalContent = (
    <div
      style={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        flexDirection: "column",
        textAlign: "center",
      }}
    >
      <p>{message}</p>
      <div>
        <DatePicker
          label="Select Last Date"
          value={activeTo ? activeTo : null}
          onChange={(newValue: object | null) => {
            newValue && handleChange(newValue);
          }}
          renderInput={(params: TextFieldProps) => (
            <TextField color="primary" sx={{ marginTop: "1.5em" }} {...params} />
          )}
        />
      </div>
      <br />
      <div>
        <Button
          variant="contained"
          style={{ marginRight: "2em", backgroundColor: `${rejectColor ? rejectColor : ""}` }}
          onClick={handleReject}
        >
          {rejectLabel}
        </Button>
        <Button
          variant="contained"
          style={{ backgroundColor: `${acceptColor ? acceptColor : ""}` }}
          onClick={handleAccept}
        >
          {acceptLabel}
        </Button>
      </div>
    </div>
  );

  return (
    <>
      <Button
        variant="contained"
        disabled={openDisabled}
        onClick={() => setOpen(true)}
        sx={{ backgroundColor: openColor }}
      >
        {openLabel}
      </Button>
      <BasicModal open={open}>{modalContent}</BasicModal>
    </>
  );
};
