import { Alert, AlertColor, Button, Snackbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Roles, useOrganizations, useParticipants, useRoles } from "../../../redux/hooks";
import Paper from '@mui/material/Paper';
import { Link, useRouteMatch } from "react-router-dom";
import { AcceptRejectModal } from "../../../components/Modals/AcceptRejectModal";
import AddIcon from '@mui/icons-material/Add';
import { deleteParticipant, getParticipantsByOrganizationId, resetParticipantData } from "../../../redux/participants/participantsHelpers";
import { OrganizationSelection } from "../../../components/ComboBox/OrganizationSelection";
import { useEffect } from "react";
import { Loading } from "../../../components/Loading/Loading";
import React from "react";
import { ResetParticipantModal } from "../../../components/Modals/ResetParticipantModal";

type State = {
    warningOpen: boolean;
    severity: AlertColor;
    alertMessage: string;
  };

export const ParticipantsTable: React.FC = () => {
    let { url } = useRouteMatch();
    const allParticipants = useParticipants().allParticipants.data;
    const [, authorities] = useRoles(Roles.organization);
    const {data, pending} = useOrganizations().selectedOrganization;
    const [alertState, setAlertState] = React.useState<State>({
        severity: "success",
        warningOpen: false,
        alertMessage: "",
      });
      const { warningOpen, severity, alertMessage } = alertState;
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertState({ ...alertState, warningOpen: false });
  };
  const handleReset = async (participantId: string, activeTo: string) => {
    resetParticipantData(participantId, activeTo)
      .then((res) => {
        setAlertState({ ...alertState, warningOpen: true, severity: "success", alertMessage: JSON.stringify(res) });
      })
      .catch((err) => {
        setAlertState({ ...alertState, warningOpen: true, severity: "error", alertMessage: JSON.stringify(err) });
      });
  };

    useEffect(() => {
        if(data?.id)
        getParticipantsByOrganizationId(data.id)
    }, [data?.id])

    return (
        <>
            <p style={{marginBottom: '0.5em', fontSize: 24}}>Participants</p>
            <OrganizationSelection callback={getParticipantsByOrganizationId} />
            {
                pending && <Loading />
            }
            {
                data && !pending &&
                <TableContainer 
                sx={{marginTop: '1.5em'}}
                component={Paper}
                >
                <Table aria-label={'Data table'}>
                    <TableHead>
                    <TableRow>
                        <TableCell >Code</TableCell>
                        <TableCell >Description</TableCell>
                        <TableCell sx={{width: '2em'}}></TableCell>
                        <TableCell sx={{width: '2em'}}></TableCell>
                        <TableCell sx={{ width: "2em" }}></TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {allParticipants && allParticipants.map((row) => (
                        <TableRow
                        key={row.id}
                        sx={{
                            "&:hover": {
                                background: "#efefef"
                            }
                        }}
                        >
                        <TableCell >{row.code}</TableCell>
                        <TableCell >{row.description}</TableCell>

                        <TableCell align='center'>
                            {  
                                authorities.includes('update') &&                          
                                <Link to={`${url}/edit/${row.id}`} style={{ textDecoration: 'none' }}>
                                    <Button 
                                        variant="contained"
                                    >
                                        Edit
                                    </Button>
                                </Link>
                            }
                        </TableCell>
                        <TableCell align='center'>
                            {
                                authorities.includes('delete') &&
                                <AcceptRejectModal
                                    openLabel='Delete'
                                    openColor='red'
                                    acceptLabel='Yes'
                                    acceptColor='red'
                                    rejectLabel='No'
                                    message='Are you sure you want to delete this record?'
                                    callBack={() => deleteParticipant(row.id)}
                                />
                            }
                        </TableCell>
                    <TableCell align="center">
                      {authorities.includes("update") && (
                        <ResetParticipantModal
                          openLabel="Reset"
                          openColor="red"
                          acceptLabel="Yes"
                          acceptColor="red"
                          rejectLabel="No"
                          message="Are you sure you want to reset this record? Reseting will permanently delete all the records for this participant. This action is NOT reservisble."
                          callBack={(activeTo: string) => handleReset(row.id, activeTo)}
                        />
                      )}
                    </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        width: '100%',
                        height: 'auto',
                    }}>
                        {
                            authorities.includes('write') &&
                                <Link to={`${url}/create`}>
                                    <Button 
                                        variant="contained"
                                        onClick={() => {}}
                                        sx={{
                                            margin: '1em',
                                            paddingRight: '0.75em'
                                        }}
                                    >
                                        New
                                        <AddIcon 
                                            sx={{
                                                fontSize: '1.3em',
                                                marginLeft: '0.3em',
                                                width: '1.35em'
                                            }}
                                        />
                                    </Button>
                                </Link>
                        }
                    </div>
                </TableContainer>
            }
            <Snackbar
                open={warningOpen}
                autoHideDuration={6000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {alertMessage}
        </Alert>
      </Snackbar>
        </>
    )
}