import { store } from "./store";
import { resetCareTeamsState } from "./careTeams/careTeamsSlice";
import { resetParticipantsState } from "./participants/participantsSlice";
import { resetReportsState } from "./reports/reportsSlice";
import { AuthenticationResponse, checkTokensLocalStorage, getToken, removeTokensLocalStorage } from "./tokens/tokensHelpers";
import { resetTokensState} from "./tokens/tokensSlice";
import { Roles, useRoles } from "./hooks";
import { getParticipantsByCareTeamId, getParticipantsByTokenOrgId } from "./participants/participantsHelpers";
import { CreateAnalyticsEvent } from "./analytics/analyticsHelpers";
import { AnalyticsTag, AnalyticsEventName } from "../analytics";

export function logOut(){
    removeTokensLocalStorage()
    store.dispatch(resetReportsState())
    store.dispatch(resetParticipantsState())
    store.dispatch(resetCareTeamsState())
    store.dispatch(resetTokensState())
}

export async function logIn(email: string, password: string): Promise<AuthenticationResponse> {
    const tokensInLocalStorage = checkTokensLocalStorage();

    if(tokensInLocalStorage) {
        return {
            authenticated: true,
            message: null
        };
    }

    return await getToken(email, password)
    // CreateAnalyticsEvent(AnalyticsEventName.USER_LOGIN, AnalyticsTag.AUTHENTICATION).Save()
}

export const useDataInit = () => {
    const [organizationAccess] = useRoles(Roles.organization)
    const [careTeamAccess] = useRoles(Roles.careteam)
    const [participantAccess] = useRoles(Roles.participant)

    if(organizationAccess) {
        getParticipantsByTokenOrgId()
    }

    if(!organizationAccess && careTeamAccess) {
        getParticipantsByCareTeamId()
    }

    if(!organizationAccess && !careTeamAccess && participantAccess ) {
        getParticipantsByCareTeamId()
    }

}

export const encodeRequestBody = (body: any) => {
    var formBody = [];
    for (var property in body) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(body[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    var requestBody = formBody.join("&");
    return requestBody
}

export const getHeaders = () => {
    if(!store.getState().tokens.accessToken) return;

    var token = store.getState().tokens.accessToken;
    
    var headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded'
    } 
    return headers;
}