interface SelectionProps {
    data: { key: string; value: string }[];
    callback: (id: string) => void; 
}


export const Selection = ({data, callback}: SelectionProps) => {
    return (
        <select style={styles.select} onChange={(e) => callback(e.target.value)}>
            {data.map((option: any) => (
                <option key={option.key} style={styles.option} value={option.key}>
                    {option.value}
                </option>
            ))}
        </select>
    );
};

const styles: { [key: string]: React.CSSProperties } = {
    select: {
        width: "100%",
        padding: "0.5em",
        borderRadius: "0.25em",
        border: "1px solid #ccc",
        fontSize: "1em",
    },
    option: {
        padding: "0.5em",
        margin: "0.5em",
        borderRadius: "0.25em",
        border: "1px solid #ccc",
        fontSize: "1em",
    },
};
