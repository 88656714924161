import React, { useEffect, useState } from 'react';
import Chart from "react-apexcharts";
import { colors } from '../../../assets/styles';
import { CreateAnalyticsEventData } from '../../../redux/hooks';

export const AnalyticsBarGraph: React.FC = () => {
    const {data, pending} = CreateAnalyticsEventData().events
    const eventCategories = [...new Set(data.map(data => data.data.name))]

    const categoriesCount = data.reduce((prev, curr) => {
        const total = prev.get(curr.data.name)
        prev.set(curr.data.name, total ? total + 1 : 1)
        return prev
    }, new Map<string, number>())

    const seriesData = eventCategories.reduce((prev: number[], curr) => {
        prev.push(categoriesCount.get(curr)!)
        return prev
    },[])

    const options: ApexCharts.ApexOptions = {
        xaxis: {
          categories: eventCategories,

        }
    }
    const series: ApexAxisChartSeries = [{
        name: 'series-1',
        data: seriesData,
        color: '#4FBDC4'
    }]

    return (
        <>
            <Chart 
                options={options} 
                series={series} 
                type="bar" 
                width={'100%'} 
                height={250} 
                loading={pending.toString()}
            />
        </>
    )
}

export const EventsTimeSeries: React.FC = () => {
    const {events} = CreateAnalyticsEventData()
    const [tsData, setTsData] = useState<any[]>()

    useEffect(() => {
        const eventsSorted = events.data.reduce((prev: any[], curr) => {
            prev.push([curr.time, curr.data.name])
            return prev
        },[])
        setTsData(eventsSorted)
    },[events.data])

    type datetime = "datetime";
    var dateTime : datetime = "datetime"
    
    interface opts {
        series: any,
        seriesIndex: any,
        dataPointIndex: any,
        w: any
    }
    
    const yValues = ["Normal", "Low", "Medium", "High"]
    
    var divStyle= `
    background-color: white;
    padding: 0.5em;
    `
    var options = {
        colors:[colors.primary],
        stroke: {
            width: 2,
        },
        tooltip: {
            custom: function(opts: opts) {
                let anxietyLevel = opts.series[opts.seriesIndex][opts.dataPointIndex]
                return (
                    `<div style='${divStyle}'>
                    <span>
                    Strong Emotion: ${yValues[anxietyLevel]}
                    </span>
                    </div>`
                    )
                }
            },
            xaxis: {
                type: dateTime,
                labels: {
                    datetimeUTC: false
                },
                line: {
                    show: true
                },
                tickPlacement: 'on',
                tickAmount: 100,
                stroke: {
                    curve: 'stepline'
                },
            },
            yaxis: {
                max: 3,
                tickAmount: 3,
                labels: {
                    show: true,
                    formatter: (value: any) => { return yValues[value]}
                }
            },
            chart: {
                dataLabels: {
                    enabled: false
                },
                toolbar: {
                    tools: {
                        download: false
                    }
                },
            }
        }
        
        var series: ApexAxisChartSeries = [{
            name: "Anxiety Level",
            data: tsData as any[]
        }]
        
        return (
            <>
            {
                tsData &&
                tsData.length > 0 &&
                <Chart 
                type="line"
                width="100%"
                height="400px"
                sx={{

                }}
                series={series}
                options={options}
                />
            }
        </>
    )
}
