import '../../../components/Forms/Forms.css'
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { WearablesTable } from './WearablesTable';
import { CreateWearable } from './CreateWearable';
import { EditWearable } from './EditWearable';

export const Wearables: React.FC = () => {
        let { path } = useRouteMatch();

    return (
        <>
        <Switch>
            <Route exact path={path}>
                <WearablesTable />
            </Route>
            <Route path={`${path}/edit/:id`}>
                <EditWearable />
            </Route>
            <Route path={`${path}/create/:careTeamId`}>
                <CreateWearable />
            </Route>
        </Switch>
        </>
    )
}
