import { Button, TextField } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { Link, useHistory, useParams } from "react-router-dom"
import { SiteSelection } from "../../../components/ComboBox/SiteSelection"
import { AcceptRejectModal } from "../../../components/Modals/AcceptRejectModal"
import { getCareTeamById, updateCareTeam } from "../../../redux/careTeams/careTeamsHelpers"
import { useCareTeams } from "../../../redux/hooks"

interface Params {
    id: string
}

export const EditCareTeam: React.FC = () => {
    const history = useHistory();
    let { id } = useParams<Params>();
    const careTeam = useCareTeams().selectedCareTeam.data
    const [code, setCode] = useState("")
    const [siteId, setSiteId] = useState("")
    const _site_id = useCallback(() => {return siteId}, [siteId])

    if(!careTeam) history.push('/admin/careteams')
    
    useEffect(() => {
        getCareTeamById(id)
    },[id])
    
    useEffect(() => {
        if(careTeam.length > 0) {
            careTeam[0].hasOwnProperty("code") && setCode(careTeam[0].code)
            careTeam[0].hasOwnProperty("siteId") && setSiteId(careTeam[0].siteId!)
        }
    },[careTeam])
    
    const handleChange = (field: string, value: string) => {
        switch(field) {
            case 'code':
                setCode(value);
                return;
        }
    }

    const handleSubmit = () => {
        updateCareTeam({
            id: id,
            code: code,
            siteId: siteId
        })
        
        history.push('/admin/careteams')
    }

    return (
        <div className="form-page-wrapper">
            <div className='form-wrapper'>
                <h2 className="form-header">Edit Care Team</h2>
                <p className="form-description">Fill out the fields below and click 'Edit', or click 'Cancel' to go back.</p>
                    <TextField
                        required
                        label="Code"
                        value={code}
                        sx={{marginTop: '0.5em', marginBottom: '1em'}}
                        onChange={(e) => handleChange('code', e.target.value)}
                    />
                    <SiteSelection 
                        callback={(id: string) => setSiteId(id)}
                        defaultId={_site_id()}
                    />
                    <div className="form-buttons-wrapper">
                        <Link to={'#'} onClick={() => history.goBack()}>
                            <Button 
                                variant="contained"
                            >
                                Cancel
                            </Button>
                        </Link>   
                        <Link to={'#'}>
                            <AcceptRejectModal
                                openLabel='Edit'
                                openDisabled={false}
                                acceptLabel='Yes'
                                acceptColor='red'
                                rejectLabel='No'
                                message='Are you sure you want to edit this record?'
                                callBack={handleSubmit}
                            />
                        </Link>
                    </div>
            </div>
        </div>
    )
}