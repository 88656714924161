import { Button } from "@mui/material"
import { useState } from "react"
import { BasicModal } from "./BasicModal"

interface AcceptRejectModalProps {
    callBack: Function,
    message: string,
    openLabel: string,
    openDisabled?: boolean,
    openColor?: string,
    acceptLabel: string,
    rejectLabel: string,
    acceptColor?: string,
    rejectColor?: string
}

export const AcceptRejectModal: React.FC<AcceptRejectModalProps> = (Props: AcceptRejectModalProps) => {
    const { 
        callBack, 
        message, 
        openLabel,
        openDisabled,
        openColor, 
        acceptLabel, 
        rejectLabel,
        acceptColor,
        rejectColor,
    } = Props

    const [open, setOpen] = useState(false)

    const handleAccept = () => {
        callBack()
        setOpen(false)
    }

    const handleReject = () => {
        setOpen(false)
    }

    const modalContent = 
        <div style={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            flexDirection: 'column'
        }}>
            <p>{message}</p><br/>
            <div>
                <Button 
                    variant="contained" 
                    style={{marginRight: '2em', backgroundColor: `${acceptColor ? acceptColor: ''}`}}
                    onClick={handleAccept}
                >
                    {acceptLabel}
                </Button>
                <Button 
                    variant="contained"
                    style={{backgroundColor: `${rejectColor ? rejectColor: ''}`}}
                    onClick={handleReject}
                >
                    {rejectLabel}
                </Button>
            </div>
        </div>
 
    return (
        <>
            <Button
                variant="contained"
                disabled={openDisabled}
                onClick={() => setOpen(true)}
                sx={{backgroundColor: openColor}}
            >
                {openLabel}
            </Button>
            <BasicModal
            open={open}
            >
                {modalContent}
            </BasicModal>
        </>
    )
}