import { DataGrid } from "@mui/x-data-grid"
import { useCallback, useEffect, useState } from "react"
import { StyleSheet } from "../../../assets/styles"
import { AnalyticsUserSelection } from "../../../components/ComboBox/AnalyticsUserSelection"
import { OrganizationSelection } from "../../../components/ComboBox/OrganizationSelection"
import { PlatformSelection } from "../../../components/ComboBox/PlatformSelection"
import { DatePickerSingleExactTime } from "../../../components/Dates/DatePickerSingleExactTime"
import { getEventsByOrganizationId, sortEvents } from "../../../redux/analytics/analyticsHelpers"
import { CreateAnalyticsEventData, useUsers } from "../../../redux/hooks"
import { getUsersByOrganizationId } from "../../../redux/users/usersHelpers"
import { AnalyticsBarGraph } from "./AnalyticsGraphs"
import { indexOf } from "lodash"

export default function Analytics(){
    const {events} = CreateAnalyticsEventData()
    const {allUsers} = useUsers()
    const [organizationId, setOrganizationId] = useState<string | undefined>()
    const [userId, setUserId] = useState<string>('all')
    const [platform, setPlatform] = useState<string>('all')
    const [startAt, setStartAt] = useState<number>(new Date().setHours(0, 0, 0, 0));
    const [endAt, setEndAt] = useState<number>(new Date().getTime());
    const [columns, setColumns] = useState([{field: '', headerName: '', width: 75}])

    const rows = events.data?.map(event => {
        const row = {
            id: event._id,
            platform: event.data.platform,
            tag: event.tag,
            name: event.data.name,
            timeZone: event.data.timeZone,
            createdAt: event.createdAt,
            version: event.version,
            platform_version: event.data.platform_version,
            api_version: event.version || event.api_version,
            email: allUsers.find(user => user.id === event.sender.userId)?.email,
            wearableId: event.sender.wearableId,
            params: event.data.params,
            userId: event.sender.userId,
            resolution_width: event.data.resolution?.width,
            resolution_height: event.data.resolution?.height,
            userAgent: event.sender.userAgent
        }
        return Object.assign(row, event.data.params)
    })
    // Render data in chronological order from top to bottom
    rows.reverse()

    //event.data.params object key + value pairs are unknown and depend on implementation
    // const params resolves to an array of unique objects from rows
    const all_params = rows.reduce((acc: Array<{field: string, headerName: string, width: number}>, row) => { 
        let param_fields
        // some events do not contain params
        if(row.params) {
            param_fields = Object.keys(row.params).map(field => {
                return {field: field, headerName: field, width: 150}
            })
            acc.push(...param_fields)
        }
        return acc
    },[])
    
    // params objects will have duplicate values between object
    // map unique objects to define the required columns
    const params = [...new Map(all_params.map((item) => [item.field, item])).values()];

    useEffect(() => {
        var columns = [
            {field: 'api_version', headerName: 'API v.', width: 100},
            {field: 'platform_version', headerName: 'Platform v.', width: 100},
            {field: 'platform', headerName: 'Platform', width: 100},
            {field: 'tag', headerName: 'Tag', width: 125},
            {field: 'name', headerName: 'Name', width: 150}
        ]
        if(platform === 'all' || platform === 'WATCH') {
            columns = [...columns,
                {field: 'wearableId', headerName: 'Wearable ID', width: 200},
            ]
        }
    
        if(platform !== 'WATCH') {
            columns = [...columns,
                {field: 'email', headerName: 'Email', width: 200},
            ]
        }

        columns = [...columns,
            {field: 'timeZone', headerName: 'Time Zone', width: 150},
            {field: 'createdAt', headerName: 'Created At', width: 200},
            {field: 'resolution_width', headerName: 'Width', width: 75},
            {field: 'resolution_height', headerName: 'Height', width: 75},
            {field: 'userAgent', headerName: 'User Agent', width: 200},
            ...params
        ]

        setColumns(columns)

    // Need this rerender based on platform, not params
    // eslint-disable-next-line
    },[platform])


    const handleOrganizationSelection = useCallback((id: string) => {
        if(id === organizationId) return
        setOrganizationId(id)
        getUsersByOrganizationId(id)
        getEventsByOrganizationId(id, platform.toLowerCase(), userId, startAt, endAt)
    },[organizationId, userId, platform, startAt, endAt])

    const handleUserSelection = useCallback((id: string) => {
        setUserId(id) 
    },[])

    const handlePlatformSelection = useCallback((platform: string) => {
        setPlatform(platform)
    },[])

    useEffect(() => {
        sortEvents(platform.toLowerCase(), userId, startAt, endAt)
    },[platform, userId, startAt, endAt])
 
    return (
        <>
            <p style={{marginBottom: '1em', fontSize: 24}}>Analytics</p>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <OrganizationSelection callback={handleOrganizationSelection}/>
                <div style={styles.pair}>
                <AnalyticsUserSelection 
                    callback={handleUserSelection}
                    disabled={!organizationId}
                />
                <PlatformSelection 
                    callback={handlePlatformSelection}
                    disabled={!organizationId}
                />

                </div>
                <div style={styles.pair}>
                    <DatePickerSingleExactTime
                        selectCallBack={(from: number) => {
                            setStartAt(from)
                        }}
                        disabled={!organizationId}
                        value={startAt}
                        defaultValue={new Date().setHours(0, 0, 0, 0)}
                        //minDateUnix={new Date().setHours(0, 0, 0, 0)}
                        maxDateUnix={endAt}
                        label={"Start Date"}
                        style={{width: 300}}
                    />
                    <DatePickerSingleExactTime 
                        selectCallBack={(_, to: number) => {
                            setEndAt(to)
                        }}
                        disabled={!organizationId}
                        value={endAt}
                        minDateUnix={startAt}
                        maxDateUnix={new Date().getTime()}
                        label={"End Date"}
                        style={{width: 299}}
                    />
                </div>
            </div>
            <AnalyticsBarGraph />
            <div style={{height: events.data.length > 0 ? 450 : 200, marginRight: 8}}>
                <DataGrid
                    loading={events.pending}
                    columns={columns}
                    rows={rows}
                    getRowId={row => {return rows.indexOf(row)}}
                />
            </div>
        </>

    )
}

const styles: StyleSheet = {
    pair: {
        display: 'inline-flex',
        flexWrap: 'wrap',
        gap: 24,
        paddingTop: 24,
    },
}