import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Stack, TextField, TextFieldProps } from "@mui/material";
import { useEffect, useState } from "react";

interface Props {
    selectCallBack: (from: number, to: number) => void
    disabled?: boolean
    selectedDate?: string
    defaultValue?: number
    minDateUnix?: number
    maxDateUnix?: number
    value?: number
    label?: string
    style?: object
}

export const DatePickerSingleExactTime: React.FC<Props> = (props: Props) => {
    const {
        selectCallBack, 
        disabled, 
        defaultValue, 
        minDateUnix, 
        maxDateUnix,
        label,
        value
    } = props;
    const [ date, setDate ] = useState<object | null>();
    const [ _minDate, setMinDate ] = useState<Date>();
    const [ _maxDate, setMaxDate ] = useState<Date>();

    useEffect(() => {
        if(minDateUnix)
        setMinDate(new Date(minDateUnix))
    },[minDateUnix])

    useEffect(() => {
        if(maxDateUnix)
        setMaxDate(new Date(maxDateUnix))
    },[maxDateUnix])

    useEffect(() => {
        if(defaultValue)
        setDate(new Date(defaultValue))
    },[defaultValue])

    useEffect(() => {
        if(value)
        setDate(new Date(value))
    },[value])

    const selectDate = (_date: object | null) => {
        if(_date) {
            setDate(_date)
            let timestamp = new Date(_date.toString())
            let from = timestamp.setHours(0, 0, 0, 0)
            let to = timestamp.setHours(24,0,0,0) -1
            selectCallBack(from, to)
        }
    }

    return (
        <Stack spacing={2} direction="row" sx={{...props.style, alignItems: 'center'}}>
            <DatePicker
                label={label || "Choose a date"}
                renderInput={(props: TextFieldProps) => 
                    <TextField color="primary" 
                        onKeyDown={(e) => e.preventDefault()}
                        style={{width: '100%'}}
                        {...props} 
                    />
                }
                value={date}
                onChange={(date: object | null) => selectDate(date)}
                disabled={disabled}
                minDate={_minDate ? _minDate : undefined}
                maxDate={_maxDate ? _maxDate : undefined}
            />
        </Stack>
        
    )
}