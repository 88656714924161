import '../../../components/Forms/Forms.css'
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { CreateUser } from "./CreateUser"
import { UsersTable } from "./UsersTable"
import { EditUser } from "./EditUser"

export const Users: React.FC = () => {
        let { path } = useRouteMatch();

    return (
        <>
        <Switch>
            <Route exact path={path}>
                <UsersTable />
            </Route>
            <Route path={`${path}/edit/:id`}>
                <EditUser />
            </Route>
            <Route path={`${path}/create`}>
                <CreateUser />
            </Route>
        </Switch>
        </>
    )
}