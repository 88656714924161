import '../../../components/Forms/Forms.css'
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { ParticipantsTable } from "./ParticipantsTable";
import { EditParticipant } from "./EditParticipants";
import { CreateParticipant } from "./CreateParticipant";

export const Participants: React.FC = () => {
        let { path } = useRouteMatch();

    return (
        <>
        <Switch>
            <Route exact path={path}>
                <ParticipantsTable />
            </Route>
            <Route path={`${path}/edit/:id`}>
                <EditParticipant />
            </Route>
            <Route path={`${path}/create`}>
                <CreateParticipant />
            </Route>
        </Switch>
        </>
    )
}
