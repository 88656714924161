import { Participant, ParticipantDTO, ParticipantWearable } from '../../interfaces/Participant';
import { Wearable } from '../../interfaces/Wearables';
import { ChartTypes } from '../components/interfaces';
import { DB_ENDPOINT } from '../db';
import { parseResForError } from '../errors/errorHelpers';
import { getHeaders } from '../helpers';
import { getReportsByRange } from '../reports/reportsHelpers';
import { store } from '../store';
import { validateTokenExpiry } from '../tokens/tokensHelpers';
import { getAllWearables, getWearableTypes, getWearablesByCareTeamId} from '../wearables/wearablesHelpers';
import { 
    FetchArgs, 
    PostArgs, 
    PutArgs 
} from './interfaces';
import {  
    fetchParticipantsByCareTeamId, 
    fetchParticipantsByOrganizationId,
    fetchParticipantsByUserId,
    postParticipant,
    putParticipant,
    removeParticipant,
    selectParticipantById,
    removeSelectedParticipant,
    fetchParticipantWearablesByUserId
} from './participantsSlice';

export async function getParticipantsByUserId(userId: string) {
    await validateTokenExpiry();
    await store.dispatch(fetchParticipantsByUserId(userId));
}

export async function getParticipantWearablesByUserId(userId: string) {
    await validateTokenExpiry();
    await store.dispatch(fetchParticipantWearablesByUserId(userId))
}

export async function getParticipantsByCareTeamId(id: string | null = null) {
    await validateTokenExpiry();
    const { tokenData } = store.getState().tokens;

    const careTeamId = id ? id : tokenData.care_team_id;
    if(!careTeamId) return;
    
    const args: FetchArgs = { careTeamId };
    await store.dispatch(fetchParticipantsByCareTeamId(args));
}

export async function getParticipantsByTokenOrgId() {
    await validateTokenExpiry();
    const { tokenData } = store.getState().tokens;
    let organizationId: string;

    if(!tokenData.organization_id) return;
    else
    organizationId  = tokenData.organization_id;

    const args: FetchArgs = { organizationId };
    await store.dispatch(fetchParticipantsByOrganizationId(args));
}

export async function getParticipantsByOrganizationId(orgId: null | string = null) {
    await validateTokenExpiry()
    let organizationId: string

    if(orgId) {
        organizationId = orgId
    } else return;

    if(!organizationId) return;
    
    const args: FetchArgs = { organizationId };
    await store.dispatch(fetchParticipantsByOrganizationId(args));
}

export async function handleParticipantRefresh() {
    const organizationId = store.getState().organizations.selectedOrganization.data?.id
    if(organizationId) {
        getParticipantsByOrganizationId(organizationId)
    } else {
        getParticipantsByOrganizationId()
    }
}

export async function updateParticipant(args: PutArgs) {
    await validateTokenExpiry()
    await store.dispatch(putParticipant(args))
    .then(res => parseResForError(res))
    await handleParticipantRefresh()
}

export async function createParticipant(args: PostArgs) {
    await validateTokenExpiry()
    await store.dispatch(postParticipant(args))
    .then(res => parseResForError(res))
    await handleParticipantRefresh()
}

export async function deleteParticipant(id: string) {
    await validateTokenExpiry()
    await store.dispatch(removeParticipant(id))
    .then(res => parseResForError(res, 405, 
        `The participant could not be deleted. Please ensure there are
        no wearables assigned to this participant.`))
    await handleParticipantRefresh()
}
export async function resetParticipantData(participantId: string, activeTo: string): Promise<any> {
    await validateTokenExpiry();
    const activeUser = store.getState().tokens.tokenData.user_id;
    const requestBody = { participantId: participantId, activeUser, time: new Date(activeTo).getTime() };
    const PATH = DB_ENDPOINT;
    return fetch(PATH + `/dev/participant/reset`, {
      body: JSON.stringify(requestBody),
      method: "DELETE",
      headers: { ...getHeaders(), "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .catch((res) => res.json());
  }

export function selectParticipant(participantId: string, obtainCharts: boolean) {
    store.dispatch(selectParticipantById(participantId))
    // obtainCharts && getDefaultParticipantChartData(participantId)
}

export function searchParticipant(participantId: string) {
    return store.getState().participants.allParticipants.data.find(
        participant => participant.id === participantId
    )
}

export function getDefaultParticipantChartData(participantId: string) {
    const chartType = store.getState().components.charts.selected
    const today = new Date()
    today.setHours(24, 0, 0, 0)
    let day = 1000 * 60 * 60 * 24
    const now = today.getTime()

    if(chartType === ChartTypes[0]) {
        getReportsByRange(participantId, now-day, now)
    }
    if(chartType === ChartTypes[1]) {
        getReportsByRange(participantId, now-8*day, now)
    }
}

export function resetSelectedParticipant() {
    store.dispatch(removeSelectedParticipant())
}

export async function ParticipantDTOFactory(participantWearables: ParticipantWearable[]): Promise<ParticipantDTO[]> {

    let participantDTOs: ParticipantDTO[] = []

    // For each participant, get wearables by care team Id
    for (let i = 0; i < participantWearables.length; i++) {
        const p = participantWearables[i]
        const participantDTO: ParticipantDTO = {
            participant_code: p.code,
            participantId: p.id,
            description: p.description,
            device_type: p.device_name,
            wearable_mac_address: p.identifier
        }
        participantDTOs = [...participantDTOs, participantDTO]
    }

    return participantDTOs
}