import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Main } from "./views/Main/Main";
import SignIn from "./views/SignIn/SignIn";
import { Dashboard } from "./views/Dashboard/Dashboard";
import { Theme } from "./assets/Theme";
import { Home } from "./views/Home/Home";
import { Organizations } from "./views/Admin/Organizations/Organizations";
import { Users } from "./views/Admin/Users/Users";
import { Sites } from "./views/Admin/Sites/Sites";
import { CareTeams } from "./views/Admin/CareTeams/CareTeams";
import { Participants } from "./views/Admin/Participants/Participants";
import { useTokens, useUsers } from "./redux/hooks";
import { Wearables } from "./views/Admin/Wearables/Wearables";
import { ScatterPlot } from "./components/Charts/ScatterPlot/ScatterPlot";
import { Settings } from "./views/Settings/Settings";
import { LocalizationProvider } from "@mui/x-date-pickers";
import DateFnsUtils from "@date-io/date-fns";
import { BASE_PATH } from "./redux/db";
import { Insights } from "./views/Insights/Insights";
import { ReleaseNotes } from "./views/Insights/ReleaseNotes";
import Events from "./views/Admin/Analytics/Analytics";
import Pairing from "./views/Pairing/Pairing";
import MetaBaseView from "./views/Reports/MetaBase";
import PairingView from "./views/Pairing/Pairing";
import { getUserDetails } from "./redux/users/usersHelpers";

const App: React.FC = () => {
    const { username, user_id } = useTokens().tokenData;
    const { userDetails } = useUsers();

    if (user_id && !userDetails) {
        getUserDetails(user_id);
    }
    
    const DefaultRouter = () => _DefaultRouter(username || "");
    return (
        <LocalizationProvider dateAdapter={DateFnsUtils}>
            <Theme>
                <div className="App">
                    {userDetails &&
                    userDetails.role_name === "ROLE_PAIRING_VENDOR" ? (
                        <VendorRouter />
                    ) : (
                        <DefaultRouter />
                    )}
                </div>
            </Theme>
        </LocalizationProvider>
    );
};

const _DefaultRouter = (username: string) => {
    return (
        <Router basename={BASE_PATH}>
            <Switch>
                {/* Client facing routes */}
                <Route exact path="/">
                    <Main>
                        <Home />
                    </Main>
                </Route>
                <Route path="/console">
                    <Main>
                        <Dashboard />
                    </Main>
                </Route>
                <Route path="/insights/release-notes">
                    <Main>
                        <ReleaseNotes />
                    </Main>
                </Route>
                <Route path="/reports">
                    <Main>
                        <MetaBaseView />
                    </Main>
                </Route>
                <Route path="/insights">
                    <Main>
                        <Insights />
                    </Main>
                </Route>
                <Route path="/settings">
                    <Main>
                        <Settings />
                    </Main>
                </Route>
                <Route path="/signin">
                    <SignIn />
                </Route>
                <Route path="/pairing">
                    <Main>
                        <Pairing />
                    </Main>
                </Route>
                {/*  Admin routes */}
                {username.includes("@awakelabs.com") && (
                    <>
                        <Route path="/admin/organizations">
                            <Main>
                                <Organizations />
                            </Main>
                        </Route>
                        <Route path="/admin/sites">
                            <Main>
                                <Sites />
                            </Main>
                        </Route>
                        <Route path="/admin/users">
                            <Main>
                                <Users />
                            </Main>
                        </Route>
                        <Route path="/admin/careteams">
                            <Main>
                                <CareTeams />
                            </Main>
                        </Route>
                        <Route path="/admin/participants">
                            <Main>
                                <Participants />
                            </Main>
                        </Route>
                        <Route path="/admin/wearables">
                            <Main>
                                <Wearables />
                            </Main>
                        </Route>
                        <Route path="/admin/analytics">
                            <Main>
                                <Events />
                            </Main>
                        </Route>
                        <Route path="/charts/scatter">
                            <Main>
                                <ScatterPlot />
                            </Main>
                        </Route>
                    </>
                )}
                <Route path="*">
                    <Main>
                        <Home />
                    </Main>
                </Route>
            </Switch>
        </Router>
    );
};

// Router with restricted access to third party vendor tools
const VendorRouter = () => {
    return (
        <Router basename={BASE_PATH}>
            <Switch>
                <Route exact path="/">
                    <Main>
                        <PairingView />
                    </Main>
                </Route>
                <Route exact path="/pairing">
                    <Main>
                        <PairingView />
                    </Main>
                </Route>
            </Switch>
        </Router>
    );
};

export default App;
