import React, { useEffect, useState } from "react";
import { SwipeableDrawer } from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import FeedIcon from "@mui/icons-material/Feed";
import WatchIcon from "@mui/icons-material/Watch";
import SsidChartIcon from "@mui/icons-material/SsidChart";
import InsightsIcon from "@mui/icons-material/Insights";
import LogoutIcon from "@mui/icons-material/Logout";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import SettingsIcon from "@mui/icons-material/Settings";
import { Link } from "react-router-dom";
import { colors } from "../../assets/styles";
import { useWindowDimensions } from "../../hooks/hooks";
import { logOut } from "../../redux/helpers";
import { removeSelectedParticipant } from "../../redux/participants/participantsSlice";
import { store } from "../../redux/store";
import { LAYOUT_BREAKPOINT } from "../../views/Main/Main";
import { MenuButton } from "../Buttons/Buttons";
import "./Menu.css";
import { Roles, useRoles, useTokens, useUsers } from "../../redux/hooks";
import { BASE_PATH } from "../../redux/db";
import { resetSelectedParticipant } from "../../redux/participants/participantsHelpers";
import { AnalyticsTag, AnalyticsEventName, ViewParams } from "../../analytics";
import { CreateAnalyticsEvent } from "../../redux/analytics/analyticsHelpers";
import { getUserDetails } from "../../redux/users/usersHelpers";
import { ALLOWED_ROLES } from "../../views/Reports/MetaBase";

interface Props {
    callback: (open: boolean) => void;
    open: boolean;
}

export const Menu: React.FC<Props> = (Props: Props) => {
    const { width } = useWindowDimensions();
    const path = window.location.pathname;
    const {
        username: un,
        user_id: uid,
        organization_id: oid,
    } = useTokens().tokenData;
    const { userDetails } = useUsers();
    const [credentials, setCredentials] = useState<{
        username: string;
        user_id: string;
        organization_id: string;
    } | null>(null);
    const [organizationAccess] = useRoles(Roles.organization);
    // Analytics
    const logoutEvent = CreateAnalyticsEvent(
        AnalyticsEventName.USER_LOGOUT,
        AnalyticsTag.AUTHENTICATION
    );
    const pageViewEvent = CreateAnalyticsEvent<ViewParams>(
        AnalyticsEventName.MENU_LINK,
        AnalyticsTag.VIEW
    );

    if (!userDetails?.role_name && uid) {
        getUserDetails(uid);
    }
    useEffect(() => {
        if (un && uid && oid) {
            setCredentials({
                username: un,
                user_id: uid,
                organization_id: oid,
            });
            getUserDetails(uid);
        }
    }, [un, uid, oid]);

    const handleClick = (link: string, next?: Function) => {
        pageViewEvent.Save({
            params: {
                page: link,
            },
        });
        // handleClick wraps onClick function, logging event first
        next && next();
    };

    const toggleDrawer = (open: boolean) => () => {
        Props.callback(open);
    };

    const toggleDrawerAndResetDashboard = () => {
        Props.callback(false);
        if (path !== `${BASE_PATH}/console`)
            store.dispatch(removeSelectedParticipant());
    };

    const resetDashboard = () => {
        if (path !== `${BASE_PATH}/console`)
            store.dispatch(removeSelectedParticipant());
    };

    const handleLogout = async () => {
        // Logout event must be awaited so that the access token is retained
        // as logging out deletes the token
        // await logoutEvent.Save().then(() => logOut());
        logOut();
    };

    if (
        width <= LAYOUT_BREAKPOINT &&
        userDetails?.role_name !== "ROLE_PAIRING_VENDOR"
    )
        return (
            <>
                <SwipeableDrawer
                    open={Props.open}
                    anchor={"left"}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                >
                    <div
                        className="menu"
                        style={{ backgroundColor: colors.primary }}
                    >
                        <img
                            src={`${process.env.PUBLIC_URL}/img/logo.svg`}
                            alt="Awake Labs"
                            style={{ width: "100%" }}
                        />
                        <Link
                            to={"/"}
                            onClick={() =>
                                handleClick("home", toggleDrawer(false))
                            }
                        >
                            <MenuButton>
                                <HomeIcon />
                                <p>Home</p>
                            </MenuButton>
                        </Link>
                        <Link
                            to={"/console"}
                            onClick={() =>
                                handleClick(
                                    "dashboard",
                                    toggleDrawerAndResetDashboard
                                )
                            }
                        >
                            <MenuButton>
                                <SsidChartIcon />
                                <p>Dashboard</p>
                            </MenuButton>
                        </Link>
                        {userDetails &&
                        ALLOWED_ROLES.includes(userDetails?.role_name) ? (
                            <Link
                                to={"/reports"}
                                onClick={() => handleClick("reports")}
                            >
                                <MenuButton>
                                    <FeedIcon />
                                    <p>Reports</p>
                                </MenuButton>
                            </Link>
                        ) : null}
                        <Link
                            to={"/insights"}
                            onClick={() =>
                                handleClick(
                                    "insights",
                                    resetSelectedParticipant
                                )
                            }
                        >
                            <MenuButton>
                                <InsightsIcon />
                                <p>Insights</p>
                            </MenuButton>
                        </Link>
                        {credentials?.username &&
                            credentials.username.includes("@awakelabs.com") &&
                            organizationAccess && (
                                <AdminAccordian
                                    callback={toggleDrawer(false)}
                                />
                            )}
                        <Link
                            to={"/settings"}
                            onClick={() => handleClick("settings")}
                        >
                            <MenuButton>
                                <SettingsIcon />
                                <p>Settings</p>
                            </MenuButton>
                        </Link>
                        <Link to={"/signin"} onClick={() => handleLogout()}>
                            <MenuButton>
                                <LogoutIcon />
                                <p>Log Out</p>
                            </MenuButton>
                        </Link>
                        <div
                            className="contact-info"
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                width: 224,
                                position: "fixed",
                                bottom: 0,
                            }}
                        >
                            <div>
                                <b>Get in touch:</b>
                                <a
                                    href="mailto:support@awakelabs.com"
                                    onClick={() =>
                                        handleClick("support@awakelabs.com")
                                    }
                                >
                                    <p>support@awakelabs.com</p>
                                </a>
                                <br />
                            </div>
                        </div>
                    </div>
                </SwipeableDrawer>
            </>
        );

    return (
        <div
            style={{
                position: "relative",
            }}
        >
            <div className="menu" style={{ backgroundColor: colors.primary }}>
                <div
                    style={{
                        position: "fixed",
                        top: 0,
                        width: 224,
                    }}
                >
                    <img
                        src={`${process.env.PUBLIC_URL}/img/logo.svg`}
                        alt="Awake Labs"
                        style={{ width: "100%" }}
                    />
                    {userDetails?.role_name === "ROLE_PAIRING_VENDOR" ? (
                        <Link
                            to={"/pairing"}
                            onClick={() => handleClick("beta/organizations")}
                        >
                            <MenuButton>
                                <WatchIcon />
                                <p>Pairing</p>
                            </MenuButton>
                        </Link>
                    ) : (
                        <>
                            <Link to={"/"} onClick={() => handleClick("home")}>
                                <MenuButton>
                                    <HomeIcon />
                                    <p>Home</p>
                                </MenuButton>
                            </Link>
                            <Link
                                to={"/console"}
                                onClick={() =>
                                    handleClick("dashboard", resetDashboard)
                                }
                            >
                                <MenuButton>
                                    <SsidChartIcon />
                                    <p>Dashboard</p>
                                </MenuButton>
                            </Link>
                            <Link
                                to={"/insights"}
                                onClick={() =>
                                    handleClick(
                                        "insights",
                                        resetSelectedParticipant
                                    )
                                }
                            >
                                <MenuButton>
                                    <InsightsIcon />
                                    <p>Insights</p>
                                </MenuButton>
                            </Link>
                            {userDetails &&
                            ALLOWED_ROLES.includes(userDetails?.role_name) ? (
                                <Link
                                    to={"/reports"}
                                    onClick={() => handleClick("reports")}
                                >
                                    <MenuButton>
                                        <FeedIcon />
                                        <p>Reports</p>
                                    </MenuButton>
                                </Link>
                            ) : null}
                            {credentials?.username &&
                                credentials.username.includes(
                                    "@awakelabs.com"
                                ) &&
                                organizationAccess && <AdminAccordian />}
                            <Link
                                to={"/settings"}
                                onClick={() => handleClick("settings")}
                            >
                                <MenuButton>
                                    <SettingsIcon />
                                    <p>Settings</p>
                                </MenuButton>
                            </Link>
                        </>
                    )}
                    <Link to={"/signin"} onClick={() => handleLogout()}>
                        <MenuButton>
                            <LogoutIcon />
                            <p>Log Out</p>
                        </MenuButton>
                    </Link>
                </div>
                <div
                    className="contact-info"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        width: 224,
                        position: "fixed",
                        bottom: 0,
                        alignItems: "center",
                        padding: "10px 0 10px 0",
                        backgroundColor: colors.primary,
                    }}
                >
                    <div>
                        <b>Get in touch:</b>
                        <a
                            href="mailto:support@awakelabs.com"
                            onClick={() => handleClick("support@awakelabs.com")}
                        >
                            <p>support@awakelabs.com</p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

interface AccordianProps {
    callback?: Function;
}

const AdminAccordian: React.FC<AccordianProps> = (Props: AccordianProps) => {
    const [expanded, setExpanded] = React.useState<boolean>(true);
    const { width } = useWindowDimensions();

    const handleChange = () => {
        setExpanded(!expanded);
    };

    const handleClick = () => {
        if (Props.callback) {
            Props.callback();
        }
    };

    const open = {
        maxHeight: width > LAYOUT_BREAKPOINT ? 0 : "100%",
    };

    const closed = {
        height: "0%",
        maxHeight: "0%",
    };

    const accordian = expanded ? open : closed;

    const accordianStyle = {
        ...accordian,
        overflow: "hidden",
        marginLeft: "2em",
        transition: "250ms",
    };

    return (
        <>
            <div onClick={handleChange}>
                <MenuButton>
                    <AdminPanelSettingsIcon />
                    <p>Admin</p>
                    <div
                        style={{
                            marginLeft: "auto",
                        }}
                    >
                        <KeyboardArrowDownIcon
                            sx={{
                                transform: `rotate(${
                                    expanded ? "180" : "0"
                                }deg)`,
                                transition: "250ms",
                            }}
                        />
                    </div>
                </MenuButton>
            </div>
            <div style={accordianStyle}>
                <Link to={"/pairing"} onClick={handleClick}>
                    <MenuButton>Pairing</MenuButton>
                </Link>
                <Link to={"/admin/organizations"} onClick={handleClick}>
                    <MenuButton>Organizations</MenuButton>
                </Link>
                <Link to={"/admin/sites"} onClick={handleClick}>
                    <MenuButton>Sites</MenuButton>
                </Link>
                <Link to={"/admin/careteams"} onClick={handleClick}>
                    <MenuButton>Care Teams</MenuButton>
                </Link>
                <Link to={"/admin/users"} onClick={handleClick}>
                    <MenuButton>Users</MenuButton>
                </Link>
                <Link to={"/admin/participants"} onClick={handleClick}>
                    <MenuButton>Participants</MenuButton>
                </Link>
                <Link to={"/admin/wearables"} onClick={handleClick}>
                    <MenuButton>Wearables</MenuButton>
                </Link>
                <Link to={"/admin/Analytics"} onClick={handleClick}>
                    <MenuButton>Analytics</MenuButton>
                </Link>
            </div>
        </>
    );
};
