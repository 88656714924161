import { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Redirect } from "react-router-dom";
import { logIn } from "../../redux/helpers";
import { useTokens } from "../../redux/hooks";
import { VERSION } from "../../redux/db";

function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://awakelabs.com/">
        Awake Labs
      </Link>{" "}
      {new Date().getFullYear()}
      {"."} v.{VERSION}
    </Typography>
  );
}

const theme = createTheme();

export default function SignIn() {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { pending } = useTokens();

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let email: string = data.get("email") as string;
    let password: string = data.get("password") as string;
    await logIn(email, password)
      .then((res) => {
        if (res.authenticated) {
          window.location.pathname = "/";
        } else {
          setErrorMessage(res.message);
        }
      })
    };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src="../img/logo_login.svg"
            style={{ width: "15em" }}
            alt="Awake Labs"
          />
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          {<p style={{ color: "red" }}>{errorMessage}</p>}
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />

            <Button
              type="submit"
              color="success"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 1 }}
              disabled={pending}
            >
              {pending ? "Checking Credentials" : "Sign In"}
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
