import { DataGrid } from "@mui/x-data-grid";
import { description, PairingDetails } from "./details";
import {
    useOrganizations,
    usePairing,
    useParticipants,
} from "../../redux/hooks";
import { Selection } from "./selection";
import { getOrganizations } from "../../redux/organizations/organizationsHelpers";
import { getParticipantsByOrganizationId } from "../../redux/participants/participantsHelpers";
import { useEffect, useState } from "react";
import {
    getAllPairings,
    VerifyPairing,
} from "../../redux/pairing/pairingHelpers";
import {
    Pairing,
    PairingStatus,
    setLastPaired,
} from "../../redux/pairing/pairingSlice";
import _ from "lodash";
import { store } from "../../redux/store";

export default function PairingView() {
    const { allOrganizations } = useOrganizations();
    const { allParticipants } = useParticipants();
    const { lastPaired, pairings, error } = usePairing();
    const [_pairings, setPairings] = useState<Pairing[]>([]);
    const [pin, setPin] = useState("");
    const [participantId, setParticipantId] = useState("");

    // Alert user if there is an error
    useEffect(() => {
        if (error) {
            alert(error);
        }
    }, [error]);

    // Fetch organizations if not already fetched
    useEffect(() => {
        if (allOrganizations.data.length === 0) {
            getOrganizations().then(() => {
                getAllPairings();
            });
        }
    }, []);

    // Set default participant id
    useEffect(() => {
        setParticipantId(allParticipants.data[0]?.id);
    }, [allParticipants.data]);

    // Reset PIN after successful pairing
    useEffect(() => {
        if (lastPaired) {
            setPin("");
        }
    }, [lastPaired]);

    useEffect(() => {
        // Set pairings with valid participants by organization
        const _pairings = pairings.filter((pairing) => {
            return allParticipants.data.find(
                (participant) => participant.id === pairing.participant_id
            );
        });
        setPairings(_pairings);
    }, [pairings, allParticipants.data]);

    useEffect(() => {
        // Set last paired
        // If this is empty, last paired GUI will be omitted.
        const _lastPaired = pairings.find(
            (pairing) => pairing.participant_id === participantId
        );

        store.dispatch(setLastPaired(_lastPaired));
    },[pairings])

    // Submit and verify pairing
    function handleSubmit() {
        if (pin.length !== 6) {
            alert("PIN must be 6 characters long");
            return;
        }
        if (participantId === "") {
            alert("Please select a participant");
            return;
        }

        // Verify pairing
        VerifyPairing({
            pin: pin,
            participant_id: participantId,
        }).then(() => {
            // Refresh all pairings
            getAllPairings();
        });
    }

    return (
        <div>
            <div style={styles.headerContainer}>
                <div style={styles.verifyContainer}>
                    <h1>New Pairing</h1>
                    <>
                        <p>Organization</p>
                        <Selection
                            data={allOrganizations.data.map((org) => {
                                return { key: org.id, value: org.name };
                            })}
                            callback={getParticipantsByOrganizationId}
                        />
                    </>
                    <>
                        <p>Participant</p>
                        <Selection
                            data={allParticipants.data.map((p) => {
                                return { key: p.id, value: p.description };
                            })}
                            callback={setParticipantId}
                        />
                    </>
                    <>
                        <p>PIN</p>
                        <input
                            type="text"
                            id="pin"
                            name="pin"
                            style={styles.input}
                            maxLength={6}
                            value={pin}
                            onChange={(e) =>
                                setPin(e.target.value.toUpperCase())
                            }
                        />
                    </>
                    <>
                        <button style={styles.button} onClick={handleSubmit}>
                            Verify
                        </button>
                    </>
                </div>
                {lastPaired ? <PairingDetails {...lastPaired} /> : null}
            </div>
            {pairings.length > 0 && allParticipants.data.length > 0 && (
                <>
                    <hr style={styles.hr} />
                    <DataGrid
                        rows={_pairings.map((pairing) => {
                            return {
                                ...pairing,
                                status: PairingStatus[pairing.pairing_status],
                                participant: description(
                                    pairing.participant_id,
                                    allParticipants.data
                                ),
                            };
                        })}
                        getRowId={(row) => row.pairing_pin}
                        columns={pairingColumns}
                        pageSize={100}
                        rowsPerPageOptions={[5]}
                        style={{
                            height: "25em",
                        }}
                    />
                </>
            )}
        </div>
    );
}

const styles: { [key: string]: React.CSSProperties } = {
    headerContainer: {
        display: "flex",
    },
    verifyContainer: {
        display: "flex",
        flex: 2,
        flexDirection: "column",
        marginRight: "24px",
        gap: "0.75em",
        maxWidth: "300px",
    },
    lastPairedContainer: {
        flex: 3,
        margin: "1em",
        padding: "2em",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        alignItems: "space-between",
    },
    lastPairedDetails: {
        display: "flex",
        flexDirection: "column",
        rowGap: "0.5em",
    },
    input: {
        width: "95%",
        padding: "0.5em",
        borderRadius: "0.25em",
        border: "1px solid #ccc",
        fontSize: "1em",
    },
    button: {
        padding: "10px",
        width: "100%",
        boxSizing: "border-box",
        backgroundColor: "#13767B",
        color: "#fff",
        border: "none",
        borderRadius: "5px",
        cursor: "pointer",
    },
    hr: {
        margin: "24px 0",
    },
};

const pairingColumns = [
    { field: "participant", headerName: "Participant", width: 250 },
    { field: "status", headerName: "Pairing Status", width: 200 },
    { field: "pairing_pin", headerName: "Pairing PIN", width: 200 },
    { field: "wearable_identifier", headerName: "Wearable ID", width: 250 },
    { field: "participant_id", headerName: "Participant ID", width: 250 },
    { field: "created_at", headerName: "Created At", width: 250 },
    { field: "updated_at", headerName: "Updated At", width: 250 },
];
