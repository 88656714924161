import { Button, TextField } from "@mui/material"
import { useState } from "react"
import { Link, useHistory, useParams } from "react-router-dom"
import { CareTeamSelection } from "../../../components/ComboBox/CareTeamSelection"
import { AcceptRejectModal } from "../../../components/Modals/AcceptRejectModal"
import { useParticipants } from "../../../redux/hooks"
import { updateParticipant } from "../../../redux/participants/participantsHelpers"

interface Params {
    id: string
}

export const EditParticipant: React.FC = () => {
    const history = useHistory();
    let { id } = useParams<Params>();
    const participants = useParticipants().allParticipants.data;
    const participant = participants.find(p => p.id === id);
    const [description, setDescription] = useState(participant?.description);
    const [code, setCode] = useState(participant?.code);
    const [careTeamId, setCareTeamId] = useState('');

    //TODO: Handle this
    if(!participant) history.push('/admin/participants');
    
    const handleChange = (field: string, value: string) => {
        switch(field) {
            case 'description':
                setDescription(value);
                return;
            case 'code':
                setCode(value);
                return;
        }
    }

    const handleSubmit = () => {
        if(!description || !code) return;

        participant &&
        updateParticipant({
            id: participant.id,
            description: description,
            code: code,
            careTeamId: careTeamId
        })
        
        history.push('/admin/participants')
    }

    if(!participant) return <></>
    return (
        <div className="form-page-wrapper">
            <div className='form-wrapper'>
                <h2 className="form-header">Edit Participant</h2>
                <p className="form-description">Fill out the fields below and click 'Edit', or click 'Cancel' to go back.</p>
                    <TextField
                        required
                        label="Code"
                        value={code}
                        sx={{marginTop: '1.5em', marginBottom: '0.5em'}}
                        onChange={(e) => handleChange('code', e.target.value)}
                    />
                    <TextField
                        required
                        label="Description"
                        value={description}
                        sx={{marginTop: '1.5em', marginBottom: '0.5em'}}
                        onChange={(e) => handleChange('description', e.target.value)}
                    />
                    <div style={{marginTop: "1.5em"}} />
                    <CareTeamSelection callback={setCareTeamId} defaultId={participant?.care_team_id}/>
                    <div className="form-buttons-wrapper">
                        <Link to={'#'} onClick={() => history.goBack()}>
                            <Button 
                                variant="contained"
                            >
                                Cancel
                            </Button>
                        </Link>   
                        <Link to={'#'}>
                            <AcceptRejectModal
                                openLabel='Edit'
                                openDisabled={false}
                                acceptLabel='Yes'
                                acceptColor='red'
                                rejectLabel='No'
                                message='Are you sure you want to edit this record?'
                                callBack={handleSubmit}
                            />
                        </Link>
                    </div>
            </div>
        </div>
    )
}