import { useEffect } from "react"
import '../../../components/Forms/Forms.css'
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { OrganizationsTable } from "./OrganizationsTable";
import { EditOrganization } from "./EditOrganization";
import { CreateOrganization } from "./CreateOrganization"
import { getOrganizations } from "../../../redux/organizations/organizationsHelpers";

export const Organizations: React.FC = () => {
        let { path } = useRouteMatch();

        useEffect(() => {
            getOrganizations()
        })

    return (
        <>
        <Switch>
            <Route exact path={path}>
                <OrganizationsTable />
            </Route>
            <Route path={`${path}/edit/:id`}>
                <EditOrganization />
            </Route>
            <Route path={`${path}/create`}>
                <CreateOrganization />
            </Route>
        </Switch>
        </>
    )
}
