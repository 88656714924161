import { AnalyticsTag, AnalyticsEventName, ViewParams } from '../../analytics'
import { colors } from '../../assets/styles'
import { useScroll } from '../../hooks/hooks'
import { CreateAnalyticsEvent } from '../../redux/analytics/analyticsHelpers'
import './Home.css'

export const Home: React.FC = () => {
    const clickEvent = CreateAnalyticsEvent<ViewParams>(AnalyticsEventName.HOME_LINK, AnalyticsTag.VIEW)

    // Analytics
    function handleClick(link: string) {
        clickEvent.Save({params: {
                page: link
            }
        })
    }

    useScroll()
    return (
        <div className='content-wrapper'>
            <h2 className="home-title">Welcome!</h2>
            <p>
                It's nice to see you here. On this page you'll find valuable resources to help you make the most of the Awake Labs technology. 
                Our contact information is in the side menu - please reach out if you have any questions or concerns!
            </p>
            <div className='onboarding-wrapper'>
            <div className='onboarding-items'>
                <div className='onboarding-item'>
                    <div>
                        <img src="/img/icons/check.png" alt="check mark icon" className="onboarding-icon"/>
                    </div>
                    <div className='onboarding-item-right'>
                        <div className='onboarding-item-header'>
                            <h3>Get Set Up</h3>
                        </div>
                        <div className='onboarding-item-body'>
                            <p>
                                Are you new here? Start with this. We'll walk you through
                                how to set up your watch and mobile device. It will take
                                10 minutes from start to finish.
                            </p>
                        <a href="https://www.awakelabs.com/set-up" target="_blank" rel="noreferrer" onClick={() => handleClick('setup')}>
                            <button 
                                style={styles.buttonStyle} 
                                className="onboarding-button"
                                ><b>Go to Setup Page</b>
                            </button>
                        </a>
                        </div>
                    </div>
                </div>
                <div className='onboarding-item'>
                    <div>
                        <img src="/img/icons/camera.png" alt="check mark icon" className="onboarding-icon"/>
                    </div>
                    <div className='onboarding-item-right'>
                        <div className='onboarding-item-header'>
                            <h3>Video Tutorials</h3>
                        </div>
                        <div className='onboarding-item-body'>
                            <p>
                                Want to get the most out of our technology? We have detailed tutorial
                                videos to show you our best tips and tricks.
                            </p>
                        <a href="https://www.youtube.com/playlist?list=PLHtlRjALl8RJIVzKZBNeQ7_fh-Oui1gGq" target="_blank" rel="noreferrer"  onClick={() => handleClick('video_library')}>
                            <button 
                                style={styles.buttonStyle} 
                                className="onboarding-button"
                                ><b>Go to Video Library</b>
                            </button>
                        </a>
                        </div>
                    </div>
                </div>
                <div className='onboarding-item'>
                    <div>
                        <img src="/img/icons/list.png" alt="check mark icon" className="onboarding-icon"/>
                    </div>
                    <div className='onboarding-item-right'>
                        <div className='onboarding-item-header'>
                            <h3>User Guide</h3>
                        </div>
                        <div className='onboarding-item-body'>
                            <p>
                                Want to learn all the ins and outs of
                                how our technology works and the best
                                ways to use it? Our User Guide will walk
                                you through it all.
                            </p>
                        <a href="https://www.awakelabs.com/userguide" target="_blank" rel="noreferrer"  onClick={() => handleClick('user_guide')}>
                            <button 
                                style={styles.buttonStyle} 
                                className="onboarding-button"
                                ><b>Read the User Guide</b>
                            </button>
                        </a>
                        </div>
                    </div>
                </div>
                <div className='onboarding-item'>
                    <div>
                        <img src="/img/icons/question.png" alt="check mark icon" className="onboarding-icon"/>
                    </div>
                    <div className='onboarding-item-right'>
                        <div className='onboarding-item-header'>
                            <h3>FAQs</h3>
                        </div>
                        <div className='onboarding-item-body'>
                            <p>
                                Have a question? There's a good chance we've been asked before.
                                Check out this resource for answers to our most asked questions.
                            </p>
                        <a href="https://intercom.help/awake-labs/en/" target="_blank" rel="noreferrer"  onClick={() => handleClick('faq')}>
                            <button 
                                style={styles.buttonStyle} 
                                className="onboarding-button"
                                ><b>See the FAQs</b>
                            </button>
                        </a>
                        </div>
                    </div>
                </div>
                
            </div>
            </div>
        </div>
    )
}

const styles = {
    buttonStyle: {
        backgroundColor: colors.primary,
        color: colors.primary_button_text,
        padding: '0.75em 0',
        width: '15em',
        height: '3em',
        borderRadius: '0.5em',
        borderWidth: 0,
    }
}
