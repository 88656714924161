import { Button, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { CareTeamSelection } from "../../../components/ComboBox/CareTeamSelection";
import { RolesSelection } from "../../../components/ComboBox/RolesSelection";
import { AcceptModal } from "../../../components/Modals/AcceptModal";
import { AcceptRejectModal } from "../../../components/Modals/AcceptRejectModal";
import { MultiSelectParticipantsCreate } from "../../../components/MultiSelect/MultiSelect";
import { Participant } from "../../../interfaces/Participant";
import { deselectEnrolledCareTeams } from "../../../redux/careTeams/careTeamsHelpers";
import {
    useOrganizationId,
    useParticipants,
    useUserRoles,
    useUsers,
} from "../../../redux/hooks";
import { getRoles } from "../../../redux/userRoles/userRolesHelpers";
import {
    createUser,
    updateParticipantUsers,
} from "../../../redux/users/usersHelpers";
import {
    emailWarning,
    participantsWarning,
    passwordWarning,
    requiredFieldsWarning,
    validateEmail,
    validateIsEmployeeEmail,
    validatePassword,
    validateRequiredFields,
} from "../../../validation/Validate";
import { getParticipantWearablesByUserId } from "../../../redux/participants/participantsHelpers";
import { store } from "../../../redux/store";
import { ComboBox } from "../../../components/ComboBox/ComboBox";
import SelfAdvocateSelection from "../../../components/ComboBox/SelfAdvocateSelection";

export const CreateUser: React.FC = () => {
    const history = useHistory();
    const organizationId = useOrganizationId();
    const { allUsers } = useUsers();
    const { roles } = useUserRoles();
    const [selfParticipant, setSelfParticipant] = useState<
        string | undefined
    >();
    const [alias, setAlias] = useState<string | undefined>();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [careTeamId, setCareTeamId] = useState("");
    const [roleId, setRoleId] = useState("");
    const [warningOpen, setWarningOpen] = useState(false);
    const [warningMessage, setWarningMessage] = useState("");
    const [isEmployeeEmail, setIsEmployeeEmail] = useState(false);
    const allParticipants = useParticipants().allParticipants.data;
    const [participantSelection, setParticipantSelection] = useState<
        Participant[]
    >([]);

    const selfAdvocate = roles.data.find(
        (role) => role.roleName === "ROLE_SELF_ADVOCATE"
    );

    if (!organizationId) history.push("/admin/users");

    useEffect(() => {
        deselectEnrolledCareTeams();
        getRoles();
    }, []);

    function isSelfAdvocate() {
        if (!selfAdvocate) return false;
        return roleId === selfAdvocate.id;
    }

    const handleChange = (label: string, value: any) => {
        switch (label) {
            case "alias":
                setAlias(value);
                return;
            case "email":
                setEmail(value);
                setIsEmployeeEmail(validateIsEmployeeEmail(value));
                return;
            case "password":
                setPassword(value);
                return;
            case "participantSelection":
                setParticipantSelection(value);
                return;
            default:
                return;
        }
    };

    const handleSubmit = () => {
        if (!validateRequiredFields([email, password, careTeamId, roleId])) {
            setWarningMessage(requiredFieldsWarning);
            setWarningOpen(true);
            return;
        }
        if (!validateEmail(email)) {
            setWarningMessage(emailWarning);
            setWarningOpen(true);
            return;
        }
        if (!validatePassword(password)) {
            setWarningMessage(passwordWarning);
            setWarningOpen(true);
            return;
        }

        //Format ids for createUser function
        let ids = participantSelection.reduce((acc: string[], participant) => {
            acc.push(participant.id);
            return acc;
        }, []);
        createUser({
            alias: alias,
            selfParticipant:
                selfAdvocate && roleId === selfAdvocate.id
                    ? selfParticipant
                    : undefined,
            email: email,
            password: password,
            organizationId: organizationId,
            careTeamId: careTeamId,
            roleId: roleId,
            participantIds:
                selfParticipant && selfAdvocate && roleId === selfAdvocate.id
                    ? [selfParticipant]
                    : ids,
        }).then(async () => {
            const newUser = store.getState().users.data.newUser;
            if (!newUser)
                throw Error(
                    "Did not retrieve new user in response to create user"
                );
            await getParticipantWearablesByUserId(newUser.id).then(async () => {
                // Update mongodb
                await updateParticipantUsers(newUser.id);
            });
        });
        history.push("/admin/users");
    };

    return (
        <div className="form-page-wrapper">
            <AcceptModal
                message={warningMessage}
                isOpen={warningOpen}
                callBack={() => setWarningOpen(false)}
            />
            <div className="form-wrapper">
                <h2 className="form-header">Create New User</h2>
                <p className="form-description">
                    Fill out the fields below and click 'Create', or click
                    'Cancel' to go back.
                </p>
                <TextField
                    type="text"
                    label="Alias"
                    value={alias}
                    sx={{ marginTop: "1.5em" }}
                    onChange={(e) => handleChange("alias", e.target.value)}
                />
                <TextField
                    required
                    type="email"
                    label="Email"
                    value={email}
                    sx={{ marginTop: "1.5em" }}
                    onChange={(e) => handleChange("email", e.target.value)}
                />
                <TextField
                    required
                    type="password"
                    label="Password"
                    value={password}
                    className="test"
                    sx={{ marginTop: "1.5em" }}
                    onChange={(e) => handleChange("password", e.target.value)}
                />
                <div style={{ height: "1.5em" }} />
                <CareTeamSelection
                    callback={(id: string) => setCareTeamId(id)}
                />
                <div style={{ height: "1.5em" }} />
                <RolesSelection
                    callback={(id: string) => setRoleId(id)}
                    isEmployeeEmail={isEmployeeEmail}
                />
                <div style={{ height: "1.5em" }} />
                {!selfAdvocate ||
                    (roleId !== selfAdvocate.id && (
                        <MultiSelectParticipantsCreate
                            callback={(selection) =>
                                handleChange("participantSelection", selection)
                            }
                        />
                    ))}
                <>
                    {selfAdvocate && roleId === selfAdvocate.id ? (
                        <p style={{ paddingBottom: "1.5em" }}>
                            Which participant is the self advocate?
                        </p>
                    ) : (
                        <p style={{ paddingBottom: "1.5em" }}>
                            Is the user also a device wearer? If so, which
                            participant are they?
                        </p>
                    )}

                    <div style={{ height: "1.5em" }} />
                    <SelfAdvocateSelection
                        setSelfParticipant={setSelfParticipant}
                        participantSelection={isSelfAdvocate() ? allParticipants : participantSelection}
                        defaultId={selfParticipant || null}
                    />
                </>

                <div style={{ height: "1.5em" }} />
                <div className="form-buttons-wrapper">
                    <Link to={"#"} onClick={() => history.goBack()}>
                        <Button variant="contained">Cancel</Button>
                    </Link>
                    <Link to={"#"}>
                        <AcceptRejectModal
                            openLabel="Create"
                            openDisabled={false}
                            acceptLabel="Yes"
                            acceptColor="red"
                            rejectLabel="No"
                            message="Are you sure you want to create this record?"
                            callBack={handleSubmit}
                        />
                    </Link>
                </div>
            </div>
        </div>
    );
};
