import { Participant, ParticipantDTO } from "../../interfaces/Participant";
import ParticipantUser from "../../interfaces/ParticipantUser";
import { parseResForError } from "../errors/errorHelpers";
import { getHeaders, logOut } from "../helpers";
import { ParticipantDTOFactory } from "../participants/participantsHelpers";
import { store } from "../store";
import { validateTokenExpiry } from "../tokens/tokensHelpers";
import { AdminUpdateUserPasswordArgs, PostArgs, PutArgs, UpdateUserPasswordArgs } from "./interfaces";
import { adminUpdateUserPassword, fetchParticipantUsers, fetchUserDetails, fetchUserEditDetails, fetchUsers, fetchUsersByOrganizationId, postUser, putUser, removeUser, updateUserPassword } from "./usersSlice";

export async function getUsers() {
    await store.dispatch(fetchUsers());
};

export async function getUserDetails(id: string) {
    await validateTokenExpiry();
    await store.dispatch(fetchUserDetails(id));
}

export async function getUserEditDetails(id: string) {
    await validateTokenExpiry();
    await store.dispatch(fetchUserEditDetails(id));
}

export async function getUsersByOrganizationId(id: string) {
    await validateTokenExpiry();
    await store.dispatch(fetchUsersByOrganizationId(id));
}

export async function handleUserRefresh() {
    const organizationId = store.getState().organizations.selectedOrganization.data?.id
    if(organizationId) {
        getUsersByOrganizationId(organizationId)
    } else {
        getUsers()
    }
}

export async function updateUser(args: PutArgs) {
    await validateTokenExpiry();
    await store.dispatch(putUser(args))
    .then(res => parseResForError(res))
    .catch(err => {
        console.error(err)
    });
}

export async function updateParticipantUsers(userId: string) {

    const {data} = store.getState().participants.participantWearables
    
    let participantDTOs: ParticipantDTO[] = []
    // Update user participants in notification service
    participantDTOs = await ParticipantDTOFactory(data)

    await updateUserParticipantsNotificationService(userId, participantDTOs)
    return await handleUserRefresh();
}

export async function updateUserParticipantsNotificationService(userId: string, participants: ParticipantDTO[]): Promise<any> {
    const headers = getHeaders()
    headers!["Content-Type"] = "application/json"
    return fetch(`${process.env.REACT_APP_NOTIFICATIONS_ENDPOINTS}/user`,
    { 
        method: 'POST', 
        headers: headers,
        body: JSON.stringify({
        userId: userId,
            participants: participants,
        })
    } 
    )
}

export async function createUser(args: PostArgs) {
    let response: Partial<Participant> = {}
    await validateTokenExpiry();
    await store.dispatch(postUser(args))
    .then(res => {
        const err = parseResForError(res)
        if(!err) {
            response = res.payload
        }
    });
    await handleUserRefresh();
    return response as Participant
}

export async function deleteUser(id: string) {
    await validateTokenExpiry();
    await store.dispatch(removeUser(id));
    return await handleUserRefresh();
}

export async function adminChangeUserPassword(args: AdminUpdateUserPasswordArgs) {
    await validateTokenExpiry();
    return await store.dispatch(adminUpdateUserPassword(args));
}

export async function changeUserPassword(args: UpdateUserPasswordArgs) {
    await validateTokenExpiry();
    return await store.dispatch(updateUserPassword(args))
    .then(res => {
       if(!parseResForError(res)) {
           logOut();
       }
    })
}

export async function getParticipantUsers(userId: string) {
    await validateTokenExpiry();
    return await store.dispatch(fetchParticipantUsers(userId));
}

export function filterUnassignedParticipants(
    participantUsers: ParticipantUser[], participants: Participant[]
) {
    const assignedParticipantIds = participantUsers.reduce((acc:string[], p) => {
        p && acc.push(p.id);
        return acc;
    },[]);

    let unassignedParticipants = participants.filter(participant => {
        if(!assignedParticipantIds.includes(participant.id)) {
            return participant;
        } else {
            return null;
        }
    });
    return unassignedParticipants;
}