import { Button, Input } from "@mui/material";
import { useUser, useUsers } from "../../redux/hooks";
import { useCallback, useState } from "react";
const sign = require("jwt-encode");

const KEY = process.env.REACT_APP_METABASE_KEY;
const METABASE_ENDPOINT = process.env.REACT_APP_METABASE_ENDPOINT;
const METABASE_CHART_ID = process.env.REACT_APP_METABASE_CHART_ID;
export const ALLOWED_ROLES = ["ROLE_ADMIN", "ROLE_MANAGER", "ROLE_CHAMPION"];

export default function MetaBaseView() {
    const { organization_id } = useUser();
    const { userDetails } = useUsers();
    const [source, setSource] = useState(METABASE_ENDPOINT || "");
    const [key, setKey] = useState(KEY || "");
    const [chartID, setChartID] = useState(METABASE_CHART_ID || "");
    const [orgId, setOrgId] = useState(organization_id || "");

    if(userDetails && !ALLOWED_ROLES.includes(userDetails?.role_name)) {
        window.location.pathname = "/";
    }

    function token() {
        if (!key || !organization_id) {
            console.error("Missing key or organization_id")
            return "";
        };
        const id = parseInt(chartID);
        const t = generateMetaBaseToken(key, orgId, id);
        return t;
    }

    function setQAEnvironment(environment: string) {
        switch (environment) {
            case "development":
                setKey(process.env.REACT_APP_QA_DEV_METABASE_KEY);
                setSource(process.env.REACT_APP_QA_DEV_METABASE_ENDPOINT);
                setChartID(process.env.REACT_APP_QA_DEV_METABASE_CHART_ID);
                break;
            case "US":
                setKey(process.env.REACT_APP_US_DEV_METABASE_KEY);
                setSource(process.env.REACT_APP_US_DEV_METABASE_ENDPOINT);
                setChartID(process.env.REACT_APP_US_DEV_METABASE_CHART_ID);
                break;
            case "CA":
                setKey(process.env.REACT_APP_CA_DEV_METABASE_KEY);
                setSource(process.env.REACT_APP_CA_DEV_METABASE_ENDPOINT);
                setChartID(process.env.REACT_APP_CA_DEV_METABASE_CHART_ID);
                break;
        }
    }

    const getSource = useCallback(() => {
        return `${source}/embed/dashboard/${token()}#bordered=false&titled=false`;
    }, [source, key, chartID, orgId]);

    const frame = useCallback(() => {
        return (
            <iframe
                id="metabase-iframe"
                title="metabase-iframe"
                src={getSource()}
                style={{
                    width: "100%",
                    height: "100%",
                    border: "none",
                    overflow: "scroll",
                }}
            />
        );
    }, [source, key, chartID, orgId]);

    return (
        <>
            {process.env.REACT_APP_QA_TEST === "true" && (
                <>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            gap: "1rem",
                            padding: "1rem",
                            height: "50px",
                        }}
                    >
                        <Button onClick={() => setQAEnvironment("development")}>
                            Staging
                        </Button>
                        <Button onClick={() => setQAEnvironment("US")}>
                            USA
                        </Button>
                        <Button onClick={() => setQAEnvironment("CA")}>
                            Canada
                        </Button>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "flex-start",
                            flexDirection: "column",
                            gap: "1rem",
                            padding: "1rem",
                            height: "50px",
                            marginBottom: "1rem",
                        }}
                    >
                        <p>Organization ID</p>
                        <Input
                        value={orgId}
                        onChange={(e) => setOrgId(e.target.value)}
                        placeholder="Organization ID"
                        style={{
                            width: "300px",
                        }}
                    />
                    </div>
                </>
            )}
            {frame()}
        </>
    );
}

function generateMetaBaseToken(
    key: string,
    organizationId: string,
    chartID: number
): string {
    return sign(
        {
            resource: { dashboard: chartID },
            params: {
                organization_id: organizationId,
            },
            exp: Math.floor(Date.now() / 1000) + 10 * 60, // 10 minutes
        },
        key
    );
}
