import { 
    AdminUpdateWearablePasswordArgs, 
    PostArgs, 
    PutArgs 
} from "./interfaces";
import { 
    adminUpdateWearablePassword,
    fetchWearablesByCareTeamId, 
    fetchWearableTypes, 
    postWearable, 
    putWearable, 
    removeWearable, 
    removeWearables
} from "./wearablesSlice";
import { Participant } from "../../interfaces/Participant";
import { Wearable } from "../../interfaces/Wearables";
import { store } from "../store";
import { validateTokenExpiry } from "../tokens/tokensHelpers";
import { getParticipantsByCareTeamId } from "../participants/participantsHelpers";
import { parseResForError } from "../errors/errorHelpers";
import { fetchWearables } from "./wearablesSlice";

export async function getAllWearables() {
    await validateTokenExpiry();
    await store.dispatch(fetchWearables());
}

export async function getWearablesByCareTeamId(id: string) {
    await validateTokenExpiry();
    await getParticipantsByCareTeamId(id);
    await store.dispatch(fetchWearablesByCareTeamId(id));
}

export async function getWearableTypes() {
    await validateTokenExpiry();
    await store.dispatch(fetchWearableTypes());
}

export function deselectWearables() {
    store.dispatch(removeWearables());
}

export async function createWearable(args: PostArgs, careTeamId: string) {
    await validateTokenExpiry()
    await store.dispatch(postWearable(args))
    .then(res => parseResForError(res))
    return await store.dispatch(fetchWearablesByCareTeamId(careTeamId))
}

export async function updateWearable(args: PutArgs) {
    const careTeamId = store.getState().careTeams.selectedCareTeam.data[0].id
    await validateTokenExpiry()
    await store.dispatch(putWearable(args))
    .then(res => parseResForError(res))
    return await store.dispatch(fetchWearablesByCareTeamId(careTeamId))
}

export async function deleteWearable(id: string) {
    const careTeamId = store.getState().careTeams.selectedCareTeam.data[0].id
    await validateTokenExpiry()
    await store.dispatch(removeWearable(id))
    return await store.dispatch(fetchWearablesByCareTeamId(careTeamId))
}

export async function adminChangeWearablePassword(args: AdminUpdateWearablePasswordArgs) {
    await validateTokenExpiry();
    return await store.dispatch(adminUpdateWearablePassword(args))
}

export const mergeParticipantCodesWithWearables = (
    wearables: Wearable[],
    participants: Participant[]
    ) => {

        if(!wearables || !participants) return [];

        const wearablesCopy = wearables.reduce((acc: Wearable[], wearable) => {
            let participant = participants.find(
                p => p.id === wearable.participantId
            );

            if(!participant) return acc;
            
            let wearableWithCode = {
                ...wearable,
                participantCode: participant.code
            }

            acc.push(wearableWithCode)

            return acc;
        },[])

        return wearablesCopy
}