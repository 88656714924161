export interface Responses {
    happy: number
    anxious: number
    other: number
    none: number
    total: number
}

export interface BasicStats {
    participantId: string
    startAt: number
    endAt: number
    numberOfDaysInPilot: number
    numberOfDaysWorn: number
    percentOfDaysWorn: number
    numberOfSentNotifs: number
    numberOfNotifsWithResponse: number
    percentOfNotifsWithResponse: number
}

export interface HourlyResponses extends Responses {
    hour: number
}

export interface DailyResponses extends Responses {
    day: number
}

export interface WeeklyResponses extends Responses {
    week: number
}

export interface MonthlyResponses extends Responses {
    month: number
    total_responses: number
}

export interface NotificationStats {
    stats: 
            BasicStats[] | 
            HourlyResponses[] | 
            DailyResponses[] | 
            WeeklyResponses[] | 
            MonthlyResponses[]
}

export enum Months {
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
}