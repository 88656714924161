import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { DB_ENDPOINT } from '../db'
import { encodeRequestBody } from '../helpers'
import { store } from '../store'
import { FetchArgs, DefaultState, PutArgs, PostArgs } from './interfaces'

const ROUTE = '/user-management/care-team'
const PATH = DB_ENDPOINT + ROUTE

const getHeaders = () => {
    if(!store.getState().tokens.accessToken) return;

    var token = store.getState().tokens.accessToken;
    var headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
    }
    return headers;
}

export const fetchCareTeamById = createAsyncThunk(
    'careTeams/fetchCareTeamById',
    async(id: string) => {
        return fetch(PATH + `/${id}`,
        { 
            method: 'GET', 
            headers: getHeaders(),
        }
        )
        .then(res => res.json());
    }
)

export const fetchCareTeams = createAsyncThunk(
    'careTeams/fetchCareTeams',
    async(args: FetchArgs) => {
        const { organizationId } = args
        return fetch(PATH + `/organization/${organizationId}`,
        { 
            method: 'GET', 
            headers: getHeaders(),
        }  
        )
        .then(res => res.json());
    }
)

export const fetchCareTeamByUserId = createAsyncThunk(
    'careTeams/fetchCareTeamByUserId',
    async(userId: string) => {
        return fetch(PATH + `/user/${userId}`,
        { 
            method: 'GET', 
            headers: getHeaders(),
        }  
        )
        .then(res => res.json());
    }
)

export const fetchAllCareTeamsByUserId = createAsyncThunk(
    'careTeams/fetchAllCareTeamsByUserId',
    async(userId: string) => {
        return fetch(PATH + `/all/user/${userId}`,
        { 
            method: 'GET', 
            headers: getHeaders(),
        }  
        )
        .then(res => res.json());
    }
)

export const putCareTeam = createAsyncThunk(
    'careTeams/putCareTeam',
    async(args: PutArgs) => {
        let requestBody = encodeRequestBody(args)
        return fetch(PATH + `/${args.id}`,
            { 
                method: 'PUT', 
                headers: getHeaders(),
                body: requestBody
            }  
        )
        .then(res => res.json())
        .catch(res => res.json());
    }
)

export const postCareTeam = createAsyncThunk(
    'careTeams/postCareTeam',
    async(args: PostArgs) => {
        let requestBody = encodeRequestBody(args)
        return fetch(PATH,
            {
                method: 'POST',
                headers: getHeaders(),
                body: requestBody
            }
        )
        .then(res => res.json())
        .catch(res => res.json());
    }
)

export const removeCareTeam = createAsyncThunk(
    'careTeams/removeCareTeam',
    async(args: {id: string}) => {
        return fetch(PATH + `/remove/${args.id}`,
            {
                method: 'DELETE',
                headers: getHeaders(),
            }
        )
        .then(res => res.json())
        .catch(res => res.json());
    }
)

const initialState: DefaultState = {
    allCareTeams: {
        pending: false,
        data: []
    },
    selectedCareTeam: {
        data: [],
        pending: false
    },
    enrolledCareTeams: {
        data: [],
        pending: false
    }
}

const careTeamsSlice = createSlice({
    name: 'careTeams',
    initialState,
    reducers: {
        resetCareTeamsState(state) {
            state.allCareTeams.data = []
            state.selectedCareTeam.data = []
        },
        selectCareTeamById(state, action) {
            let careTeam = state.allCareTeams.data.find(careTeam => {
                return careTeam.id === action.payload;
            })
            if(careTeam)
            state.selectedCareTeam.data = [careTeam]
        },
        removeSelectedCareTeam(state) {
            state.selectedCareTeam.data = []
        },
        removeEnrolledCareTeams(state) {
            state.enrolledCareTeams.data = []
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCareTeamById.pending, (state) => {
            state.selectedCareTeam.pending = true;
        })
        builder.addCase(fetchCareTeamById.fulfilled, (state, action) => {
            state.selectedCareTeam.data = [action.payload.careTeam];
            state.selectedCareTeam.pending = false;
        })
        builder.addCase(fetchCareTeams.pending, (state) => {
            state.allCareTeams.pending = true;
        })
        builder.addCase(fetchCareTeams.fulfilled, (state, action) => {
            state.selectedCareTeam.data = [];
            state.allCareTeams.data = action.payload;
            state.allCareTeams.pending = false;
        })
        builder.addCase(fetchCareTeamByUserId.pending, (state) => {
            state.allCareTeams.pending = true;
        })
        builder.addCase(fetchCareTeamByUserId.fulfilled, (state, action) => {
            state.selectedCareTeam.data = [action.payload.careTeam];
            state.selectedCareTeam.pending = false;
        })
        builder.addCase(fetchAllCareTeamsByUserId.pending, (state) => {
            state.enrolledCareTeams.pending = true;
        })
        builder.addCase(fetchAllCareTeamsByUserId.fulfilled, (state, action) => {
            state.enrolledCareTeams.data = action.payload.careTeams;
            state.enrolledCareTeams.pending = false;
        })
        builder.addCase(putCareTeam.pending, (state) => {
            state.allCareTeams.pending = true;
        })
        builder.addCase(putCareTeam.fulfilled, (state) => {
            state.allCareTeams.pending = false;
        })
        builder.addCase(removeCareTeam.pending, (state) => {
            state.allCareTeams.pending = true;
        })
        builder.addCase(removeCareTeam.fulfilled, (state) => {
            state.allCareTeams.pending = false;
        })
    }
})

export const { 
    resetCareTeamsState, 
    selectCareTeamById, 
    removeSelectedCareTeam,
    removeEnrolledCareTeams
} = careTeamsSlice.actions;

export default careTeamsSlice.reducer;