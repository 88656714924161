import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { RootState, AppDispatch, store } from './store'
import { checkTokensLocalStorage } from './tokens/tokensHelpers'
import { storeTokensFromLocalStorage } from './tokens/tokensSlice'
import { isEqual } from 'lodash'
import { sortHeatMapData } from './reports/reportsHelpers'
import { useEffect, useState } from 'react'
import { getRoles, getUserRolesByUserId } from './userRoles/userRolesHelpers'

// Use throughout the app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export const useVisualizations = () => useAppSelector(state => state.visualizations, isEqual)
export const useTokens = () => useAppSelector(state => state.tokens, isEqual)
export const useUser = () => useAppSelector(state => state.tokens.tokenData, isEqual)
export const useUsers = () => useAppSelector(state => state.users.data, isEqual)
export const useCareTeams = () => useAppSelector(state => state.careTeams, isEqual)
export const useOrganizations = () => useAppSelector(state => state.organizations, isEqual)
export const useParticipants = () => useAppSelector(state => state.participants, isEqual)
export const usePairing = () => useAppSelector(state => state.pairing, isEqual)
export const useSelectedParticipant = () => useAppSelector(state => state.participants.selectedParticipant, isEqual)
export const useReportsByRange = () => useAppSelector(state => state.reports.reportsByRange.data, isEqual)
export const useReports = () => useAppSelector(state => state.reports)
export const useNotifications = () => useAppSelector(state => state.notifications.allNotifications, isEqual)
export const useSites = () => useAppSelector(state => state.sites, isEqual)
export const useWearables = () => useAppSelector(state => state.wearables, isEqual)
export const useChartTypes = () => useAppSelector(state => state.components.charts, isEqual)
export const useUserRoles = () => useAppSelector(state => state.userRoles, isEqual)
export const useErrors = () => useAppSelector(state => state.errors, isEqual)
export const CreateAnalyticsEventData = () => useAppSelector(state => state.analytics, isEqual)

export const useHeatMapData = () => {
    const { startAt, endAt } = useReports().reportsByRange
    const reportData = useAppSelector(state => state.reports.reportsByRange.data)
    const heatMapData = sortHeatMapData(startAt, endAt, reportData)
    return heatMapData
}

/** When the application window is closed or freshed, redux context is lost.
 * This hook fetches JWT tokens from local storage, if any, and reinstates them
 * in the redux context.
 */
export const useLocalStorageOnRefresh = () => {
    const tokenInReduxState = useTokens().accessToken;
    if(!tokenInReduxState) {
        store.dispatch(storeTokensFromLocalStorage())
    }
}

/**
 * Specifies available roles
 */
export enum Roles {
    organization='organization',
    careteam='care_team',
    participant='participant',
}
export interface Authority {
    access: boolean,
    authorities: string[]
}
/** 
 * @param role enum Roles
 * @returns[boolean, string[]]
 * 
 * 0. A boolean value indicating role access for the provided role param
 * 1. An array of permitted CRUD operations for param 'role'.
 */
export const useRoles = (role: string): [boolean, string[]] => {
    const { authorities } = useAppSelector(state => state.tokens.tokenData, isEqual)

    var auth: Authority = {access: false, authorities: []}
    if(authorities)
    authorities.forEach(authority => {
        let role_permission = authority.split(':')
        if(role_permission[0] === role) {
            auth.access = true
            auth.authorities?.push(role_permission[1])
        }
    })

    return [auth.access, auth.authorities];
}

export const useRoleName = (): string[] => {
    const {data: userRole} = useUserRoles().userRoles
    const {data: roles} = useUserRoles().roles
    const [roleName, setRoleName] = useState<string | undefined>(undefined)
    const {user_id} = useUser()
    useEffect(() => {
        getRoles().then(() => {
        if (user_id) {
            getUserRolesByUserId(user_id)
        }})
    },[])

    useEffect(() => {
        if (userRole.length > 0 && roles.length > 0) {
            const roleName = roles.find(r => r.id == userRole[0].roleId)?.roleName
            setRoleName(roleName)
        }
    },[userRole, roles])

    if (roleName) return [roleName]
    return []
}

export const useOrganizationId = () => {
    const token = useTokens().tokenData
    const [organizationAccess] = useRoles(Roles.organization)
    const { selectedOrganization } = useOrganizations()

    if(!organizationAccess) {
        if(token.organization_id)
        return token.organization_id
    }

    return selectedOrganization.data?.id!;
}

/** 
* Returns True if a user is logged in, else false.
*/
export function useLoggedIn() {
    const tokenInState = useTokens().accessToken
    const tokensInLocalStorage = checkTokensLocalStorage();
    if(tokenInState || tokensInLocalStorage) return true
    return false
}

export const BetaOrganizations = [
    // Awake labs
    "dfd374bd-3063-4c7a-8238-ca358bd0ef04",
    // ES
    // "0482084e-25d8-4df0-9f39-892983524e48"
]

export const BetaUsers = [
    "040737a0-0332-4b6a-9416-03bd5178a7bf",
    "206afaa9-dca8-4f69-80bf-af86dd982563",
    "67b1217a-5f06-47cd-80f0-a483d88f68f7",
]

export function useAccessBetaReports() {
    const {organization_id, user_id} = useTokens().tokenData
    const allowedOrganizations = [
        // Awake labs
        "dfd374bd-3063-4c7a-8238-ca358bd0ef04",
        // ES
        // "0482084e-25d8-4df0-9f39-892983524e48"
    ]
    const allowedUsers = [
        "040737a0-0332-4b6a-9416-03bd5178a7bf",
        "206afaa9-dca8-4f69-80bf-af86dd982563",
        "67b1217a-5f06-47cd-80f0-a483d88f68f7",
    ]
    if(!organization_id || user_id?.length == 0 || !user_id) return {betaAccess: false}
    return {betaAccess: (allowedOrganizations.includes(organization_id) || allowedUsers.includes(user_id[0]))}
}