import { 
    createSlice, 
    createAsyncThunk 
} from '@reduxjs/toolkit';
import { DB_ENDPOINT } from '../db';
import { encodeRequestBody } from '../helpers';
import { store } from '../store';
import { 
    AdminUpdateWearablePasswordArgs, 
    DefaultState, 
    PostArgs, 
    PutArgs 
} from './interfaces';

const ROUTE = '/user-management'
const PATH = DB_ENDPOINT + ROUTE

const getHeaders = () => {
    if(!store.getState().tokens.accessToken) return;

    var token = store.getState().tokens.accessToken
    var headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
    }
    return headers
}

export const fetchWearables = createAsyncThunk(
    'wearables/fetchWearables',
    async() => {
        return fetch(PATH + `/wearable`,
        { 
            method: 'GET', 
            headers: getHeaders(),
        }  
        )
        .then(res => res.json())
    }
)

export const fetchWearablesByCareTeamId = createAsyncThunk(
    'wearables/fetchWearablesByCareTeamId',
    async(id: string) => {
        return fetch(PATH + `/wearable/care-team/${id}`,
        { 
            method: 'GET',
            headers: getHeaders(),
        }  
        )
        .then(res => res.json())
    }
)

export const fetchWearableTypes = createAsyncThunk(
    'wearables/fetchWearableTypes',
    async() => {
        return fetch(PATH + `/wearable-type/`,
        { 
            method: 'GET', 
            headers: getHeaders(),
        }  
        )
        .then(res => res.json())
    }
)

export const postWearable = createAsyncThunk(
    'wearables/postWearable',
    async(args: PostArgs) => {
        let requestBody = encodeRequestBody(args)
        return fetch(PATH + '/wearable',
            {
                method: 'POST',
                headers: getHeaders(),
                body: requestBody
            }
        )
        .then(res => res.json())
        .catch(res => res.json())
    }
)

export const putWearable = createAsyncThunk(
    'wearables/putWearable',
    async(args: PutArgs) => {
        let requestBody = encodeRequestBody(args)
        return fetch(PATH + `/wearable/update/${args.id}`,
            { 
                method: 'PUT', 
                headers: getHeaders(),
                body: requestBody
            }
        )
        .then(res => res.json())
        .catch(res => res.json())
    }
)

export const removeWearable = createAsyncThunk(
    'wearables/removeWearable',
    async(id: string) => {
        return fetch(PATH + `/wearable/remove/${id}`,
            {
                method: 'DELETE',
                headers: getHeaders(),
            }
        )
        .then(res => res.json())
        .catch(res => res.json())
    }
)

export const adminUpdateWearablePassword = createAsyncThunk(
    'wearables/adminUpdateWearablePassword',
    async(args: AdminUpdateWearablePasswordArgs) => {
        let requestBody = encodeRequestBody(args)
        return fetch(PATH + `/wearable/admin/change/password`,
            {
                method: 'PUT',
                headers: getHeaders(),
                body: requestBody
            }
        )
        .then(res => res.json())
        .catch(res => res.json())
    }
)

const initialState: DefaultState = {
    allWearables: {
        data: [],
        pending: false,
        emptyResult: false
    },
    wearablesBySelectedParticipant: {
        data: [],
        pending: false
    },
    wearableTypes: {
        data: [],
        pending: false
    }
}

const wearablesSlice = createSlice({
    name: 'wearables',
    initialState,
    reducers: {
        removeWearables(state) {
            state.allWearables.data = [];
            state.allWearables.emptyResult = false;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchWearables.pending, (state) => {
            state.allWearables.pending = true
        })
        builder.addCase(fetchWearables.fulfilled, (state, action) => {
            state.allWearables.data = action.payload.wearables
            state.allWearables.pending = false
        })
        builder.addCase(fetchWearablesByCareTeamId.pending, (state) => {
            state.allWearables.pending = true
        })
        builder.addCase(fetchWearablesByCareTeamId.fulfilled, (state, action) => {
            const data = action.payload.wearables;

            if(data.length === 0 || !data) {
                state.allWearables.emptyResult = true;
            } else {
                state.allWearables.emptyResult = false;
            }
            state.allWearables.data = action.payload.wearables;
            state.allWearables.pending = false
        })
        builder.addCase(fetchWearableTypes.pending, (state) => {
            state.allWearables.pending = true
        })
        builder.addCase(fetchWearableTypes.fulfilled, (state, action) => {
            state.wearableTypes.data = action.payload.wearableTypes
            state.allWearables.pending = false
        })
        builder.addCase(putWearable.pending, (state) => {
            state.allWearables.pending = true
        })
        builder.addCase(putWearable.fulfilled, (state) => {
            state.allWearables.pending = false
        })
        builder.addCase(postWearable.pending, (state) => {
            state.allWearables.pending = true
        })
        builder.addCase(postWearable.fulfilled, (state) => {
            state.allWearables.pending = false
        })
        builder.addCase(removeWearable.pending, (state) => {
            state.allWearables.pending = true
        })
        builder.addCase(removeWearable.fulfilled, (state) => {
            state.allWearables.pending = false
        })
        builder.addCase(adminUpdateWearablePassword.pending, (state) => {
            state.allWearables.pending = true
        })
        builder.addCase(adminUpdateWearablePassword.fulfilled, (state) => {
            state.allWearables.pending = false
        })
    }
})

export const { removeWearables } = wearablesSlice.actions

export default wearablesSlice.reducer