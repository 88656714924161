import { Button } from '@mui/material'
import { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { Menu } from '../../components/Menu/Menu'
import { useWindowDimensions } from '../../hooks/hooks'
import { useErrors, useLocalStorageOnRefresh, useLoggedIn } from '../../redux/hooks'
import MenuIcon from '@mui/icons-material/Menu';
import { validateTokenExpiry } from '../../redux/tokens/tokensHelpers'
import { AcceptModal } from '../../components/Modals/AcceptModal'
import { removeError } from '../../redux/errors/errorHelpers'
import { ReleaseNotesAlert } from '../../components/Alerts/ReleaseNotesAlert'

export const LAYOUT_BREAKPOINT = 1100

export const Main: React.FC = ({children}) => {
    const [menuOpen, setMenuOpen] = useState(false);
    const { width } = useWindowDimensions();
    const { active, error } = useErrors();

    useLocalStorageOnRefresh()
    useEffect(() => {
        validateTokenExpiry()
    },[])
    
    const loggedIn = useLoggedIn()

    if(!loggedIn) {
        return(
           <Redirect to="/signin" />  
        )
    }

    const styles = {
        mainContent: {
            display: "flex",
            justifyContent: "center",
            padding: width >= LAYOUT_BREAKPOINT ? "2em 2em 10em 2em" : "2em 0 10em 0",
            margin: 0,
            backgroundColor: "rgba(255, 255, 255, 0.1)",
        },
        mainContentCenter: {
            display: "flex",
            flexDirection: "column" as "column",
            width: width >= LAYOUT_BREAKPOINT ? width * 0.8 : width * 0.9
        }
    }

    if(width <= LAYOUT_BREAKPOINT)
    return(
        <div className="grid-container-dashboard"
            style={{
                display: "grid",
                height: "100vh",
                gridTemplateColumns: "1fr",
                gridTemplateRows: "4em 1fr"
            }}
        >
                    <div style={{
                        position: 'sticky',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        backgroundColor: 'white',
                        height: '3em',
                        zIndex: 100
                    }}>
                        <Button 
                        onClick={() => setMenuOpen(!menuOpen)} 
                        sx={{
                            width: '100%',
                            height: '100%',
                            color: 'white',
                            backgroundColor: '#13767B',
                            borderRadius: 0,
                            ":hover": {backgroundColor: '#13767B', opacity: '0.8'}
                        }}
                        >
                            <MenuIcon sx={{marginRight: '0.5em'}}/>
                            <p>Menu</p>
                        </Button>
                    </div>
         <Menu callback={setMenuOpen} open={menuOpen}/>
            <div className="main-content" style={styles.mainContent}>

                <div className="main-content-center" style={styles.mainContentCenter}>
                    <br />
                    <AcceptModal
                    isOpen={active}
                    message={error}
                    callBack={removeError}
                    />
                    {children}
                </div>

            </div>       
        </div>
    )

    return (
        <div className="grid-container-dashboard"
            style={{
                display: "grid",
                height: "100vh",
                gridTemplateColumns: "14em 1fr",
                gridTemplateRows: "1fr"
            }}
        >
        <Menu callback={setMenuOpen} open={menuOpen}/>
            <div className="main-content" style={styles.mainContent}>

                <div className="main-content-center" style={styles.mainContentCenter}>
                {/* <ReleaseNotesAlert/> */}
                    <br />
                    {/* Display any server error messages  from user input */}
                    <AcceptModal
                    isOpen={active}
                    message={error}
                    callBack={removeError}
                    />
                    {children}
                </div>
            </div>
        </div>
    )
}

