import { StyleSheet } from "../../assets/styles"
import '../../assets/Fonts/fonts.css'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ScrollTopButton } from "../../components/Buttons/Buttons";
import { AnalyticsTag, AnalyticsEventName, ViewParams} from "../../analytics";
import { CreateAnalyticsEvent } from "../../redux/analytics/analyticsHelpers";

export const ReleaseNotes: React.FC = () => {

    const clickEvent = CreateAnalyticsEvent<ViewParams>(AnalyticsEventName.INSIGHTS, AnalyticsTag.VIEW)

    function handleClickEvent(section: string){
        clickEvent.Save({
            params: {
                page: "release_notes",
                section: section
            }
        })
    }

    return (
        <>
            {/* Absolute positioned scroll to top button */}
            <ScrollTopButton />
            <div style={styles.header}>
                <div><p style={styles.titleText}>Awake Labs Insights Release</p></div>
            </div>
            <div style={styles.container} className={"Font-Rubik"}>
                {/* Scroll to top button */}
                {/* // CONTENTS MENU */}
                <>
                    <p style={styles.contentText}>Contents</p>

                    {/* LINKS */}
                    <div style={{maxWidth: 400}}>
                        <a style={styles.a}href="#whats-new" onClick={() => handleClickEvent("whats new")}>
                            <p style={styles.contentLinkText}>What's New</p>
                        </a>
                        <a style={styles.a}href="#dashboard" onClick={() => handleClickEvent("dashboard")}>
                            <p style={styles.contentLinkText}>Dashboard</p>
                        </a>
                        
                        <div style={styles.indentedContentText}>
                            <a style={styles.a}href="#new-insights-link" onClick={() => handleClickEvent("new insights link")}>
                                <p style={styles.contentLinkText}>New insights link</p>
                            </a>
                            <a style={styles.a}href="#insights-default-view-and-filters" onClick={() => handleClickEvent("insights default view and filters")}>
                                <p style={styles.contentLinkText}>Insights default view and filters</p>
                            </a>
                            <a style={styles.a}href="#insights-filters" onClick={() => handleClickEvent("insights filters")}>
                                <p style={styles.contentLinkText}>Insights filters</p>
                            </a>
                            <a style={styles.a}href="#insights-cards" onClick={() => handleClickEvent("insights cards")}>
                                <p style={styles.contentLinkText}>Insights cards</p>
                            </a>
                            <div style={styles.indentedContentText}>
                                <a style={styles.a}href="#notifications-statistics" onClick={() => handleClickEvent("notification statistics")}>
                                    <p style={styles.contentLinkText}>Notification statistics</p>
                                </a>
                                <a style={styles.a}href="#notifications-received-by-hour" onClick={() => handleClickEvent("notifications received by hour")}>
                                    <p style={styles.contentLinkText}>Notifications received by hour</p>
                                </a>
                                <a style={styles.a}href="#notes-created-by-hour" onClick={() => handleClickEvent("notifications created by hour")}>
                                    <p style={styles.contentLinkText}>Notes created by hour</p>
                                </a>
                                <a style={styles.a} href="#notifications-received-by-day" onClick={() => handleClickEvent("notifications received by day")}>
                                    <p style={styles.contentLinkText}>Notifications received by day</p>
                                </a>
                                <a style={styles.a}href="#notes-created-by-day" onClick={() => handleClickEvent("notes created by day")}>
                                    <p style={styles.contentLinkText}>Notes created by day</p>
                                </a>
                                <a style={styles.a}href="#notifications-received-by-month" onClick={() => handleClickEvent("notifications received by month")}>
                                    <p style={styles.contentLinkText}>Notifications received by month</p>
                                </a>
                                <a style={styles.a}href="#notes-created-by-month" onClick={() => handleClickEvent("notes creates by month")}>
                                    <p style={styles.contentLinkText}>Notes created by month</p>
                                </a>
                            </div>
                        </div>
                        <a style={styles.a}href="#contact" onClick={() => handleClickEvent("contact")}>
                            <p style={styles.contentLinkText}>Will you contact us if you have feedback?</p>
                        </a>
                        <br/>
                    
                    </div>
                </>
                {/* WHAT'S NEW */}
                <div id="whats-new">
                    <p style={styles.contentHeader}>
                        What's New
                    </p>
                    <p style={styles.contentText}>This release contains enhancements to Awake Labs Dashboard</p>
                    <p style={{...styles.contentText, paddingBottom: 0}}>Highlights of the contents include:</p>
                    
                    <ul>
                        <li style={styles.li}>New Insights page on the Awake Labs Dashboard</li>
                        <li style={styles.li}>The Insights page will provide analytical data for individual watch wearers - or participants - including
                            <ul style={{marginTop: 10}}>
                                <li style={styles.li}>Insights filtered by Emotions and Date Ranges</li>
                                <li style={styles.li}>Analysis shown by hour, day, and months</li>
                            </ul>
                        </li>
                    </ul>
                </div>

                {/* DASHBOARD */}
                <div id="dashboard">
                    <p style={styles.contentHeader}>
                        Dashboard
                    </p>
                </div>
                {/* New insights link*/}
                <>
                    <div id="new-insights-link">
                        <p style={styles.contentSubHeader}>
                            New Inights Link
                        </p>
                        <p style={styles.contentText}>A new link has been created on the Awake Labs Dashboard to direct users to the new Insights page.</p>
                        <img style={styles.notesImage} src="/img/release-notes/new_insights_link.png" alt="New insights link"/>
                        <InfoCard>
                            The new link is only accessible from a computer.  Mobile functionality may be released at a later date.
                        </InfoCard>
                    </div>
                </>
                {/* Insights default view and filters*/}
                <>
                    <div id="insights-default-view-and-filters">
                        <p style={styles.contentSubHeader}>
                            Insights default views and filters
                        </p>
                        <p style={styles.contentText}>
                            The Insights page view will vary based on a Dashboard user’s role.  The default view for the Insights page 
                            is to display data with no filter on emotions for the entire duration of a single participant’s time using 
                            the watch.  
                        </p>
                        <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                onClick={() => handleClickEvent("expand default filter view admin")}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <Typography>Default filter view for Manager or Admin</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <p style={styles.contentText}>
                                    <img style={styles.notesImage} src="/img/release-notes/default-view-manager.png" alt="insights default view and filters"/> 
                                </p>
                                </AccordionDetails>
                        </Accordion>
                        <Accordion>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                onClick={() => handleClickEvent("expand default filter view caregiver")}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <Typography>Default filter view for Care Giver</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <p style={styles.contentText}>
                                    <img style={styles.notesImage} src="/img/release-notes/default-view-care-giver.png" alt="insights default view and filters"/> 
                                </p>
                                </AccordionDetails>
                        </Accordion>
                        <br />
                        <br />
                    </div>
                </>
                {/* Insights filters */}
                <>
                    <div id="insights-filters">
                        <p style={styles.contentSubHeader}>
                            Insights filters
                        </p>
                        <p style={styles.contentText}>
                            The following filters are available to users:
                        </p>
                        <TableRow title={'User'}>
                            The list of users that the current dashboard user has access to
                            <br/><i>Note: This field does not appear for users with the Care Giver role.</i>
                        </TableRow>
                        <TableRow title={'Participant'}>
                        For <b>Admin</b> and <b>Manager</b> roles: The list of participants the selected User has access to
                        <br />For <b>Care Giver</b> role: The list of participants the dashboard user has access to                        </TableRow>
                        <TableRow title={'Emotion'}>
                            Filter the cards by All, Happy, Anxious, or Other Emotions
                        </TableRow>
                        <TableRow title={'Start Date'}>
                            This defaults to the date that the wearable of the selected participant was 
                            activated in the Awake Labs system.  The Start Date can be changed using the 
                            calendar pop-up to any date after the activation date and until today’s date.
                        </TableRow>
                        <TableRow title={'End Date'} bottom>
                            This defaults to today’s date.  This can be changed using the calendar pop-up 
                            to any date after the activation date and until today’s date.
                        </TableRow>
                        <br />
                        <br />
                    </div>
                </>
                {/* Insights cards */}
                <>
                    <div id="insights-cards">
                        <p style={styles.contentSubHeader}>
                            Insights cards
                        </p>
                            {/* Insights cards views */}
                            <div id="insights-cards-views">
                                <p style={styles.contentSubHeader2}>
                                    Insights cards views
                                </p>
                                <p style={styles.contentText}>
                                    After a user selects the filters, the cards will dynamically render to display the data 
                                    based on those selected filters.
                                </p>
                                <img style={{...styles.notesImage, maxWidth: '100%'}} src="/img/release-notes/insight-card-views.png" alt="insights card views"/>
                                <InfoCard>
                                    The above screenshot is for an Administrator or Manager Dashboard user.  
                                    The user filter does not appear for Care Giver users.  
                                <br /><br />
                                    Additionally, this view shows no Emotion filters applied.  When an Emotion filter is 
                                    applied the <i>Notifications received</i> cards switch to <i>Notes created</i>.
                                </InfoCard>
                            </div>
                            {/* Notification statistics */}
                            <div id="notifications-statistics">
                                <p style={styles.contentSubHeader2}>
                                    Notification statistics
                                </p>
                                <p style={styles.contentText}>
                                    The Notification Statistics card will display data based on the time ranges selected.
                                    This card does not change if the Emotion filter is applied, but will adjust when the date ranges are modified.
                                </p>
                                <img style={{...styles.notesImage, width: 315}} src="/img/release-notes/notification-statistics.png" alt="notification statistics"/>
                                <p style={styles.contentText}>The following fields are displayed on the Notification Statistics card:</p>
                                <TableRow title={"Number of days worn"} width={250}>
                                    This field will display the number of days the current participant has worn 
                                    the wearable in the date range selected.
                                </TableRow>
                                <TableRow title={"Percentage of days worn"} width={250}>
                                    This field will show the percentage of days the current participant has worn 
                                    the wearable in the date range selected.
                                </TableRow>
                                <TableRow title={"Number of sent notifications"} width={250}>
                                    This field will show the number of times a notification was generated and sent to the selected user based on an emotionally 
                                    triggered event in the date range selected.
                                </TableRow>
                                <TableRow title={"Number of notifications with response"} width={250} bottom>
                                    This field will show the number of times a response was created by the selected user for a generated notification 
                                    in the date range selected.
                                </TableRow>
                                <br />
                                <br />
                            </div>
                            {/* Notifications received by hour */}
                            <div id="notifications-received-by-hour">
                                <p style={styles.contentSubHeader2}>
                                    Notifications received by hour
                                </p>
                                <p style={styles.contentText}>
                                    The Notifications received by hour card displays a break down of all the notifications 
                                    that have been received each hour during the date range selected.  You can use the horizontal 
                                    scroll bar to view all hours from 0:00-23:00.  You can hover over each hour to display a 
                                    tooltip to give the exact count for a specific hour.
                                </p>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}>
                                    <img style={{...styles.notesImage, maxWidth: '80%'}} src="/img/release-notes/notifications-received-by-hour.png" alt="notifications received by hour"/>
                                </div>
                                <InfoCard>
                                    <p style={{margin: 0, padding: 0}}>
                                    The title and data of this card change dependent on the emotions filter.<br />  
                                    If the Emotions filter is not set or set to “All” the card will show <b>Notifications Received</b>.<br />
                                    If the Emotions filter is set to “Happy”, “Anxious”, or “Other” the card will show <b>Notes Created</b>.
                                    </p>
                                </InfoCard>
                            </div>
                            {/* Notes created by hour */}
                            <div id="notes-created-by-hour">
                                <p style={styles.contentSubHeader2}>
                                    Notes created by hour
                                </p>
                                <p style={styles.contentText}>
                                    The Notes created by hour card displays a break down of all the selected emotion’s 
                                    notes that have been created each hour during the date range selected.  You can use 
                                    the horizontal scroll bar to view all hours from 0:00-23:00.  You can hover over each 
                                    hour to display a tooltip to give the exact count for a specific hour.
                                <br/><br/>
                                    For example, this is a view of 'Happy' notes created during a selected date range:
                                </p>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center'
                                }}>
                                    <img style={{...styles.notesImage, maxWidth: '80%'}} src="/img/release-notes/notes-created-by-hour.png" alt="notes created by hour"/>
                                </div>
                                <InfoCard>
                                    <p style={{margin: 0, padding: 0}}>
                                        The title and data of this card change dependent on the emotions filter.<br />
                                        If the Emotions filter is set to “Happy”, “Anxious”, or “Other” the card will show <b>Notes Created</b>.<br/>
                                        If the Emotions filter is not set or set to “All” the card will show <b>Notifications Received</b>.
                                    </p>
                                </InfoCard>
                            </div>
                            {/* Notifications received by day */}
                            <div id="notifications-received-by-day">
                                <p style={styles.contentSubHeader2}>
                                    Notifications received by day
                                </p>
                                <p style={styles.contentText}>
                                    The Notifications received by day card displays a break down of all the notifications that have been received each day 
                                    of the week during the date range selected.  You can hover over each day to display a tooltip to give the exact count for a specific day.
                                </p>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-evenly'
                                }}>
                                    <img style={{...styles.notesImage, width: 315}} src="/img/release-notes/notifications-received-by-day-1.png" alt="notifications received by day"/>
                                    <img style={{...styles.notesImage, width: 315}} src="/img/release-notes/notifications-received-by-day-2.png" alt="notification received by day with tooltip"/>
                                </div>
                                <InfoCard>
                                    <p style={{margin: 0, padding: 0}}>
                                        The title and data of this card change dependent on the emotions filter.<br />
                                        If the Emotions filter is not set or set to “All” the card will show <b>Notifications Received</b>.<br/>
                                        If the Emotions filter is set to “Happy”, “Anxious”, or “Other” the card will show <b>Notes Created</b>.
                                    </p>
                                </InfoCard>
                            </div>
                            {/* Notes created by day */}
                            <div id="notes-created-by-day">
                                <p style={styles.contentSubHeader2}>
                                    Notes created by day
                                </p>
                                <p style={styles.contentText}>
                                    The Notes created by day card displays a break down of all the selected emotion’s 
                                    notes that have been created each day of the week during the date range selected.  
                                    You can hover over each day to display a tooltip to give the exact count for a specific day.
                                    <br/><br/>
                                    For example, this is view of 'Other' notes created during a selected date range:
                                </p>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'space-evenly'
                                }}>
                                    <img style={{...styles.notesImage, width: 315}} src="/img/release-notes/notes-created-by-day-1.png" alt="notifications received by day"/>
                                    <img style={{...styles.notesImage, width: 315}} src="/img/release-notes/notes-created-by-day-2.png" alt="notification received by day with tooltip"/>
                                </div>
                                <InfoCard>
                                    <p style={{margin: 0, padding: 0}}>
                                        The title and data of this card change dependent on the emotions filter. <br />
                                        If the Emotions filter is set to “Happy”, “Anxious”, or “Other” the card will show <b>Notes Created</b>.<br/>
                                        If the Emotions filter is not set or set to “All” the card will show <b>Notifications Received</b>.
                                    </p>
                                </InfoCard>
                            </div>
                            {/* Notifications received by month */}
                            <div id="notifications-received-by-month">
                                <p style={styles.contentSubHeader2}>
                                    Notifications received by month
                                </p>
                                <p style={styles.contentText}>
                                    The Notifications received by month card displays a break down of all the notifications that have been received 
                                    each month during the date range selected.  You can hover over each month to display a tooltip to give the exact 
                                    count for a specific month.
                                </p>
                                <div style={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly'
                                    }}>
                                        <img style={{...styles.notesImage, width: 315, height: 285}} src="/img/release-notes/notifications-received-by-month-1.png" alt="notifications received by day"/>
                                        <img style={{...styles.notesImage, width: 315}} src="/img/release-notes/notifications-received-by-month-2.png" alt="notification received by day with tooltip"/>
                                </div>
                                <InfoCard>
                                    <p style={{margin: 0, padding: 0}}>
                                        <b>This card view is only visible when the Emotion filter is set to “All”</b>
                                    </p>
                                </InfoCard>
                            </div>
                            {/* Notes created by month */}
                            <div id="notes-created-by-month">
                                <p style={styles.contentSubHeader2}>
                                        Notes created by month
                                </p>
                                <p style={styles.contentText}>
                                    The Notes created by month card displays a break down of all the selected emotion’s 
                                    notes that have been created each month during the date range selected.  You can hover 
                                    over each month to display a tooltip to give the exact count for a specific month.
                                    <br/><br/>
                                    For example, this is view of 'Anxious' notes created during a selected date range:
                                </p>
                                <div style={{
                                        display: 'flex',
                                        justifyContent: 'space-evenly'
                                    }}>
                                        <img style={{...styles.notesImage, width: 315, height: 285}} src="/img/release-notes/notes-created-by-month-1.png" alt="notifications received by day"/>
                                        <img style={{...styles.notesImage, width: 315}} src="/img/release-notes/notes-created-by-month-2.png" alt="notification received by day with tooltip"/>
                                </div>
                                <InfoCard>
                                    <p style={{margin: 0, padding: 0}}>
                                        <b>This card view is always visible</b>
                                    </p>
                                </InfoCard>
                            </div>
                        </div>
                    </>
                    <>
                        {/* Will you contact us if you have feedback */}
                        <div id="contact">
                            <p style={styles.contentHeader}>
                                Will you contact us if you have feedback? 
                            </p>
                            <p style={styles.contentText}>
                                We love to hear from the users of our technology. It’s the best way that we can 
                                continue to provide you with a product that you love. 
                                <br/><br/>
                                Will you contact us if you have any feedback?
                            </p>
                            <Accordion>
                                <AccordionSummary
                                onClick={() => handleClickEvent("expand will you contact us if you have any feedback: yes")}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <Typography>Yes</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <p style={styles.contentText}>
                                    Great! Thank you. You can share your feedback with us by sending an email to 
                                <a style={{...styles.a, color: '#5A5A5A'}} href="mailto:support@awakelabs.com"> support@awakelabs.com</a>
                                </p>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion>
                                <AccordionSummary
                                onClick={() => handleClickEvent("expand will you contact us if you have any feedback: no")}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                                >
                                <Typography>No, thanks</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                <p style={styles.contentText}>
                                    No problem! We hope you enjoy the Insights feature 🙂
                                </p>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    </>
            </div>
        </>
    )
}

const InfoCard: React.FC = ({children}) => {
    return(
        <div style={styles.infoCardWrapper}>
                <p style={styles.infoIcon}>
                    💡
                </p>
                <p style={styles.infoCardText}>
                    {children}
                </p>
        </div>
    )
}


const TableRow: React.FC<{title: string, width?: number, bottom?: boolean}> = ({title, width=100, bottom, children}) => {
    return (
        <div style={{...styles.tableRowWrapper, borderBottomWidth: bottom ? 1 : 0}}>
            <div style={{...styles.tableRowTitle, minWidth: width}}>{title}</div>
            <div style={styles.tableRowContent}>{children}</div>
        </div>
    )
}

const styles: StyleSheet = {
    container: {
        maxWidth: 900,
    },
    header: {
        display: 'flex',
        position: 'relative',
        width: '100%'
    },
    headerContentRight: {
        position: 'absolute',
        right: 0
    },
    titleText: {
        fontSize: 36,
        fontWeight: 500,
        marginBottom: 24
    },
    contentLinkText : {
        fontSize: 16,
        color: '#5A5A5A',
        paddingBottom: 8,
        paddingLeft: 20,
        maxWidth: 400
    },
    indentedContentText: {
        marginLeft: 32
    },
    li: {
        marginBottom: 10
    },
    a: {
        textDecoration: 'underline',
        color: 'unset'
    },
    contentHeader: {
        fontSize: 30,
        fontWeight: 500,
        paddingBottom: 24
    },
    contentSubHeader: {
        fontSize: 24,
        fontWeight: 500,
        paddingBottom: 20
    },
    contentSubHeader2: {
        fontSize: 20,
        paddingBottom: 20
    },
    contentText: {
        paddingBottom: 20
    },
    notesImage: {
        width: '100%',
        height: 'auto',
        marginBottom: 20
    },
    imgCaption: {
        fontSize: 14,
        color: '#5A5A5A',
    },
    infoCardWrapper: {
        display: 'flex',
        padding: 24,
        backgroundColor: '#F1F1EF',
        marginTop: 20,
        marginBottom: 24
    },
    infoIcon: {
        fontSize: 24
    },
    infoCardText: {
        paddingLeft: 12,
        paddingTop: 6
    },
    tableRowWrapper: {
        display: 'flex',
        width: '100%',
        borderColor: '#DEDEDE',
        borderStyle: 'solid',
        borderTopWidth: 1,
        borderRightWidth: 1,
        borderLeftWidth: 1
    },
    tableRowTitle: {
        fontWeight: 500,
        padding: 12,
        backgroundColor: '#F1F1EF'
    },
    tableRowContent: {
        padding: 12
    }
}