import { useCallback, useEffect, useState } from "react"
import { CareTeam } from "../../interfaces/CareTeam"
import { getCareTeams, selectCareTeam } from "../../redux/careTeams/careTeamsHelpers"
import { Roles, useCareTeams, useOrganizations, useRoles } from "../../redux/hooks"
import { ComboBox, Options } from "./ComboBox"

interface Props {
    callback: (id: string) => void;
    defaultId?: string;
}

export const CareTeamSelection: React.FC<Props> = ({callback, defaultId}) => {
    const { selectedOrganization } = useOrganizations();
    const { allCareTeams, selectedCareTeam } = useCareTeams();
    const [careTeamId, setCareTeamId] = useState<undefined | string>(undefined);
    const [careTeamAccess] = useRoles(Roles.careteam);
    const [dropDownOptions, setDropDownOptions] = useState([{label: "None", value: "none"}]);

    const defaultValue = useCallback(() => {
            if(selectedCareTeam.data.length === 0) {
                if(careTeamId) {
                    let careTeam = allCareTeams.data.find(ct => ct.id === careTeamId);
                    if(careTeam)
                    return {
                        label: `${careTeam.code} (${careTeam.name})`,
                        value: careTeam.id
                    }
                    return null;
                } return null;
            };
            const careTeam = selectedCareTeam.data[0]
            if(careTeam)
            return {
                label: `${careTeam.code} (${careTeam.name})`,
                value: careTeam.id
            }
        return null;
    },[selectedCareTeam, careTeamId, allCareTeams.data])

    useEffect(() => {
        if(selectedCareTeam.data.length > 0) {
            setCareTeamId(selectedCareTeam.data[0].id)
        } else {
            defaultId && setCareTeamId(defaultId)
        }
    },[selectedCareTeam.data, defaultId])

    useEffect(() => {
        if(careTeamAccess && careTeamId) {
            callback(careTeamId);
        }
    },[careTeamAccess, careTeamId, callback])

    useEffect(() => {
        if(selectedOrganization.data?.id)
        careTeamAccess && getCareTeams(selectedOrganization.data.id)
    },[selectedOrganization.data?.id, careTeamAccess])

    // Create options for drop down list
    useEffect(() => {

            let options = allCareTeams.data.reduce((acc: any[], careTeam: CareTeam) => {
                    acc.push({
                        label: `${careTeam.code} (${careTeam.name})`,
                        value: careTeam.id
                    })
                    return acc
                },[])
            setDropDownOptions(options)

    },[allCareTeams.data])

    const selectDropDown = (selection: Options | null) => {
        if(selection?.value) {
            selectCareTeam(selection.value);
        }
    }

    return (
        <>
        {
            careTeamAccess &&
            <div>
                <ComboBox 
                    label={'Care Team'} 
                    options={dropDownOptions} 
                    callback={selectDropDown}
                    defaultValue={defaultValue()}
                />
            </div>
        }
        </>
    )
}