import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useOrganizations} from "../../../redux/hooks";
import Paper from '@mui/material/Paper';
import { Link, useRouteMatch } from "react-router-dom";
import { AcceptRejectModal } from "../../../components/Modals/AcceptRejectModal";
import AddIcon from '@mui/icons-material/Add';
import { deleteOrganization } from "../../../redux/organizations/organizationsHelpers";
import { Loading } from "../../../components/Loading/Loading";

export const OrganizationsTable: React.FC = () => {
    let { url } = useRouteMatch();
    const {data, pending} = useOrganizations().allOrganizations

    return (
        <>
            <p style={{marginBottom: '1em', fontSize: 24}}>Organizations</p>
            {
                pending && <Loading />
            }
            {
                !pending &&
                <TableContainer component={Paper}>
                <Table aria-label={'Data table'}>
                    <TableHead>
                    <TableRow>
                        <TableCell >Name</TableCell>
                        <TableCell >Code</TableCell>
                        <TableCell >Created At</TableCell>
                        <TableCell sx={{width: '2em'}}></TableCell>
                        <TableCell sx={{width: '2em'}}></TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {data.map((row) => (
                        <TableRow
                        key={row.id}
                        sx={{
                            "&:hover": {
                                background: "#efefef"
                            }
                        }}
                        >
                        <TableCell >{row.name}</TableCell>
                        <TableCell >{row.code}</TableCell>
                        <TableCell >{row.createdAt}</TableCell>

                        <TableCell align='center'>
                                <Link to={`${url}/edit/${row.id}`} style={{ textDecoration: 'none' }}>
                                    <Button 
                                        variant="contained"
                                    >
                                        Edit
                                    </Button>
                                </Link>
                        </TableCell>
                        <TableCell align='center'>
                            {
                                <AcceptRejectModal
                                    openLabel='Delete'
                                    openColor='red'
                                    acceptLabel='Yes'
                                    acceptColor='red'
                                    rejectLabel='No'
                                    message='Are you sure you want to delete this record?'
                                    callBack={() => deleteOrganization({id: row.id})}
                                />
                            }
                        </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        width: '100%',
                        height: 'auto',
                    }}>
                        <Link to={`${url}/create`}>
                            <Button 
                                variant="contained"
                                onClick={() => {}}
                                sx={{
                                    margin: '1em',
                                    paddingRight: '0.75em'
                                }}
                            >
                                New
                                <AddIcon 
                                    sx={{
                                        fontSize: '1.3em',
                                        marginLeft: '0.3em',
                                        width: '1.35em'
                                    }}
                                />
                            </Button>
                        </Link>
                    </div>
                </TableContainer>
            }
        </>
    )
}