import { store } from "../store";
import { validateTokenExpiry } from "../tokens/tokensHelpers";
import { fetchAllResponses, fetchMonthlyResponses } from "./dataVisualizationsSlice";
import { FetchArgs } from "./interfaces";

export async function getAllResponses(participantId: string, userId: string, startAt: number, endAt: number) {
    const args: FetchArgs = { participantId, userId, startAt, endAt }
    await validateTokenExpiry()
    await store.dispatch(fetchAllResponses(args))
}

export async function getMonthlyResponses(participantId: string, userId: string, startAt: number, endAt: number) {
    const args: FetchArgs = { participantId, userId, startAt, endAt }
    await validateTokenExpiry()
    await store.dispatch(fetchMonthlyResponses(args))
}

export function getTimeZone() {
    const d = new Date();
    let diff = d.getTimezoneOffset();
    return (diff/60) * -1 //(minutes/hours) // -1 reverses offset
}