import { Button, TextField } from "@mui/material"
import { useEffect, useState } from "react"
import { Link, useHistory } from "react-router-dom"
import { ComboBox, Options } from "../../../components/ComboBox/ComboBox"
import { AcceptRejectModal } from "../../../components/Modals/AcceptRejectModal"
import { CareTeam } from "../../../interfaces/CareTeam"
import { getCareTeams } from "../../../redux/careTeams/careTeamsHelpers"
import { useCareTeams, useOrganizationId } from "../../../redux/hooks"
import { createParticipant } from "../../../redux/participants/participantsHelpers"

export const CreateParticipant: React.FC = () => {
    const history = useHistory();
    const careTeams = useCareTeams().allCareTeams.data;
    const organizationId = useOrganizationId()
    const [ dropDownOptions, setDropDownOptions ] = useState([{label: "None", value: "none"}])
    const [description, setDescription] = useState('')
    const [code, setCode] = useState('')
    const [careTeamId, setCareTeamId] = useState('')

    if(!organizationId) history.push('/admin/participants')

    useEffect(() => {
        getCareTeams(organizationId)
    },[organizationId])

    // Create options for drop down list
    useEffect(() => {
        if(careTeams.length > 0) {
            let options = careTeams.reduce((acc: any[], careTeam: CareTeam) => {
                    acc.push({
                        label: `${careTeam.code} (${careTeam.name})`,
                        value: careTeam.id
                    })
                    return acc
                },[])
            setDropDownOptions(options)
        }
    },[careTeams])

    const selectDropDown = (selection: Options | null) => {
        if(selection?.value) {
            setCareTeamId(selection.value);
        }
    }

    const handleChange = (field: string, value: string) => {
        switch(field) {
            case 'description':
                setDescription(value);
                return;
            case 'code':
                setCode(value);
                return;
        }
    }

    const handleSubmit = () => {
        if(!careTeamId) return;
        createParticipant({description, code, careTeamId});
        history.push('/admin/participants');
    }

    return (
        <div className="form-page-wrapper">
            <div className='form-wrapper'>
                <h2 className="form-header">Create New Participant</h2>
                <p className="form-description">Fill out the fields below and click 'Create', or click 'Cancel' to go back.</p>
                    <TextField
                        required
                        label="Description"
                        value={description}
                        sx={{marginTop: '0.5em', marginBottom: '0.5em'}}
                        onChange={(e) => handleChange('description', e.target.value)}
                    />
                    <TextField
                        required
                        label="Code"
                        value={code}
                        sx={{marginTop: '0.5em', marginBottom: '1em'}}
                        onChange={(e) => handleChange('code', e.target.value)}
                    />
                    <ComboBox
                        label={'Care Team'} 
                        options={dropDownOptions} 
                        callback={selectDropDown}
                    />

                    <div className="form-buttons-wrapper">
                        <Link to={'#'} onClick={() => history.goBack()}>
                            <Button 
                                variant="contained"
                            >
                                Cancel
                            </Button>
                        </Link>   
                        <Link to={'#'}>
                            <AcceptRejectModal
                                openLabel='Create'
                                openDisabled={false}
                                acceptLabel='Yes'
                                acceptColor='red'
                                rejectLabel='No'
                                message='Are you sure you want to create this record?'
                                callBack={handleSubmit}
                            />
                        </Link>
                    </div>
            </div>
        </div>
    )
}