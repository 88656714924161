import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useCareTeams, useOrganizations, useSites } from "../../../redux/hooks";
import Paper from '@mui/material/Paper';
import { Link, useRouteMatch } from "react-router-dom";
import { AcceptRejectModal } from "../../../components/Modals/AcceptRejectModal";
import AddIcon from '@mui/icons-material/Add';
import { OrganizationSelection } from "../../../components/ComboBox/OrganizationSelection";
import { deleteCareTeam, getCareTeamsByOrgId } from '../../../redux/careTeams/careTeamsHelpers'
import { useEffect } from "react";
import { getSitesByOrganizationId } from "../../../redux/sites/sitesHelpers";
import { Loading } from "../../../components/Loading/Loading";

export const CareTeamsTable: React.FC = () => {
    let { url } = useRouteMatch();
    const { data, pending } = useCareTeams().allCareTeams
    const selectedOrganization = useOrganizations().selectedOrganization.data
    const { allSites } = useSites()

    useEffect(() => {
        if(selectedOrganization)
        getSitesByOrganizationId(selectedOrganization.id)
    },[selectedOrganization])

    useEffect(() => {
        if(selectedOrganization)
        getSitesByOrganizationId(selectedOrganization.id)
    },[selectedOrganization])

    const parseSiteName = (siteId: string) => {
        if(allSites.data.length === 0) return "";
        let name = allSites.data.find(site => site.id === siteId)?.name
        return name ? name : ""
    }

    const handleDelete = async (id: string) => {
        await deleteCareTeam(id);
        if(selectedOrganization)
        getCareTeamsByOrgId(selectedOrganization.id);
    }

    return (
        <>
        <p style={{marginBottom: '0.5em', fontSize: 24}}>Care Teams</p>
        <OrganizationSelection callback={getCareTeamsByOrgId} /><br/>
        {
            pending && <Loading />
        }
        {
            selectedOrganization && !pending &&
            <TableContainer component={Paper}>
            <Table aria-label={'Data table'}>
                <TableHead>
                    <TableRow>
                        <TableCell >Code</TableCell>
                        <TableCell >Site</TableCell>
                        <TableCell >Created At</TableCell>
                        <TableCell sx={{width: '2em'}}></TableCell>
                        <TableCell sx={{width: '2em'}}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {data.map((row) => (
                    <TableRow
                    key={row.id}
                    sx={{
                        "&:hover": {
                            background: "#efefef"
                        }
                    }}
                    >
                    <TableCell >{row.code}</TableCell>
                    <TableCell >{parseSiteName(row.site_id!)}</TableCell>
                    <TableCell >{row.created_at}</TableCell>

                    <TableCell align='center'>
                            <Link to={`${url}/edit/${row.id}`} style={{ textDecoration: 'none' }}>
                                <Button 
                                    variant="contained"
                                >
                                    Edit
                                </Button>
                            </Link>
                    </TableCell>
                    <TableCell align='center'>
                        {
                            <AcceptRejectModal
                                openLabel='Delete'
                                openColor='red'
                                acceptLabel='Yes'
                                acceptColor='red'
                                rejectLabel='No'
                                message='Are you sure you want to delete this record?'
                                callBack={() => handleDelete(row.id)}
                            />
                        }
                    </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
                <div style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                    height: 'auto',
                }}>
                    <Link to={`${url}/create`}>
                        <Button 
                            variant="contained"
                            onClick={() => {}}
                            sx={{
                                margin: '1em',
                                paddingRight: '0.75em'
                            }}
                        >
                            New
                            <AddIcon 
                                sx={{
                                    fontSize: '1.3em',
                                    marginLeft: '0.3em',
                                    width: '1.35em'
                                }}
                            />
                        </Button>
                    </Link>
                </div>
            </TableContainer>
        }
        </>
    )
}