import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Button, TextField, TextFieldProps } from "@mui/material"
import { useState } from "react"
import { Link, useHistory, useParams } from "react-router-dom"
import { ParticipantSelection } from "../../../components/ComboBox/ParticipantSelection"
import { WearableTypeSelection } from "../../../components/ComboBox/WearableTypeSelection"
import { AcceptModal } from "../../../components/Modals/AcceptModal"
import { AcceptRejectModal } from "../../../components/Modals/AcceptRejectModal"
import { useOrganizations } from "../../../redux/hooks"
import { createWearable } from "../../../redux/wearables/wearablesHelpers"
import { macAddresssWarning, passwordWarning, requiredFieldsWarning, validateMacAddress, validatePassword } from "../../../validation/Validate"

interface Params {
    careTeamId: string
}

export const CreateWearable: React.FC = () => {
    const history = useHistory();
    let { careTeamId } = useParams<Params>();
    const  {selectedOrganization } = useOrganizations();
    const organizationId = selectedOrganization.data?.id;
    const [identifier, setIdentifier] = useState('');
    const [password, setPassword] = useState('');
    const [wearableTypeId, setWearableTypeId] = useState('');
    const [participantId, setParticipantId] = useState('');
    const [activeFrom, setActiveFrom] = useState<string | null>(null);
    const [activeTo, setActiveTo] = useState<string | null>(null);
    const [verificationWarningOpen, setVerificationWarningOpen] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');

    if(careTeamId === 'null') history.push('/admin/wearables')

    const parseDate = (date: object) => {
        let timestamp = new Date(date.toString())
        let y = timestamp.getFullYear()
        let m = timestamp.getMonth() + 1
        let d = timestamp.getDate()
        let h = timestamp.getHours()
        let min = timestamp.getMinutes()
        let s = timestamp.getSeconds()
        let ms = timestamp.getMilliseconds()
        let formatted = `${y}-${m}-${d} ${h}:${min}:${s}.${ms}`
        return formatted;
    }

    const handleChange = (field: string, value: string) => {
        switch(field) {
            case 'identifier':
                setIdentifier(value);
                return;
            case 'password':
                setPassword(value);
                return;
            case 'wearableTypeId':
                setWearableTypeId(value);
                return;
            case 'participantId':
                setParticipantId(value);
                return;
            case 'activeFrom':
                setActiveFrom(value);
                if(!activeTo) {
                    setActiveTo(value)
                }
                return;
            case 'activeTo':
                setActiveTo(value);
                return;
        }
    }

    const handleSubmit = () => {
        if(!validateMacAddress(identifier)) {
            setWarningMessage(macAddresssWarning);
            setVerificationWarningOpen(true);
            return;
        }
        if(!validatePassword(password)) {
            setWarningMessage(passwordWarning);
            setVerificationWarningOpen(true);
            return;
        } 
        if(
            !organizationId || !activeFrom || !activeTo || 
            !identifier || !password || !wearableTypeId
        ) {
            setWarningMessage(requiredFieldsWarning);
            setVerificationWarningOpen(true);
            return;
        }
        createWearable({
            identifier, 
            password, 
            participantId, 
            wearableTypeId,
            activeFrom,
            activeTo
        }, careTeamId)
        history.push('/admin/wearables');
    }

    return (
        <div className="form-page-wrapper">
            <AcceptModal 
                message={warningMessage}
                isOpen={verificationWarningOpen}
                callBack={() => setVerificationWarningOpen(false)}
            />
            <div className='form-wrapper'>
                <h2 className="form-header">Create New Wearable</h2>
                <p className="form-description">Fill out the fields below and click 'Create', or click 'Cancel' to go back.</p>
                    <TextField
                        required
                        label="Identifier"
                        value={identifier}
                        sx={{marginTop: '0.5em'}}
                        onChange={(e) => handleChange('identifier', e.target.value)}
                    />
                    <TextField
                        required
                        label="Password"
                        value={password}
                        type="password"
                        sx={{marginTop: '1.5em'}}
                        onChange={(e) => handleChange('password', e.target.value)}
                    />
                    <div style={{height: '1.5em'}}></div>
                    <ParticipantSelection 
                        callback={(id: string) => handleChange('participantId', id)} 
                    />
                    <div style={{height: '1.5em'}}></div>
                    <WearableTypeSelection
                        callback={(id: string) => handleChange('wearableTypeId', id)}
                    />

                    <DatePicker
                        label="Active From"
                        value={activeFrom}
                        onChange={(newValue: object | null) => {
                        newValue && handleChange('activeFrom', parseDate(newValue))}}
                        renderInput={(params: TextFieldProps) => 
                            <TextField 
                            color="primary" 
                            sx={{marginTop: '1.5em'}}
                            {...params} 
                        />}
                    />
                    <DatePicker
                        label="Active To"
                        value={activeTo ? activeTo : activeFrom}
                        onChange={(newValue: object | null) => {
                        newValue && handleChange('activeTo', parseDate(newValue))
                        }}
                        renderInput={(params: TextFieldProps) => 
                            <TextField 
                            color="primary" 
                            sx={{marginTop: '1.5em'}}
                            {...params} 
                        />}
                    />

                    <div className="form-buttons-wrapper">
                        <Link to={'#'} onClick={() => history.push('/admin/wearables')}>
                            <Button 
                                variant="contained"
                            >
                                Cancel
                            </Button>
                        </Link>   
                        <Link to={'#'}>
                            <AcceptRejectModal
                                openLabel='Create'
                                openDisabled={false}
                                acceptLabel='Yes'
                                acceptColor='red'
                                rejectLabel='No'
                                message='Are you sure you want to create this record?'
                                callBack={handleSubmit}
                            />
                        </Link>
                    </div>
            </div>
        </div>
    )
}