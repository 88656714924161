import { useEffect, useState } from "react"
import { StyleSheet } from "../../../assets/styles"
import { Responses } from "../../../interfaces/DataVisualization"
import '../../../assets/Fonts/fonts.css'
import { Tooltip } from "@mui/material"
import { Months } from '../../../interfaces/DataVisualization'

export interface BarChartProps {
    color: string
    barWidth: number
    data: Responses[]
    labels: string[]
    maxValue: number
    tooltipPrefix?: string
}

interface Props extends BarChartProps {
    tooltipLabel: string
    height: number
}

export const BarChart: React.FC<Props> = (props: Props) => {
    const {color, labels, height, barWidth, data} = props
    const [barData, setBarData] = useState<Responses[]>();
    const [maxBarValue, setMaxBarValue] = useState<number>(0);
    const barGraphHeight = height - (styles.labelsWrapper.height as number);
    const currentMonth = new Date().getMonth()

    useEffect(() => {
        setBarData(data)
    },[data])

    useEffect(() => {
        setMaxBarValue(props.maxValue)
    },[props.maxValue])

    // useEffect(() => {
    //     const maxTotalResponses = data.reduce((prev, curr) => {
    //         prev = curr.total > prev.total ? curr : prev
    //         return prev
    //     })
    //     setMaxBarValue(maxTotalResponses.total)
    // },[data])

    function calculateBarHeight(total: number): number {
        const barHeight = (total / maxBarValue) * barGraphHeight
        return barHeight
    }

    if(!barData) return null;

    return (
        <div>
            <div 
                style={{
                    height: barGraphHeight,
                    ...styles.container
                }}>
                {
                    data.map((_data, i) => (
                            <Tooltip 
                                placement="bottom"
                                arrow={true}
                                title={
                                    <div style={{
                                        padding: '0.5em',
                                    }}>
                                        <p style={{
                                            fontSize: 16
                                        }}>
                                        
                                        {
                                            data.length === 12 
                                            ? <>{props.tooltipPrefix}<br />{`${Months[i]} ${i > currentMonth ? 2022 : 2023}: ${_data.total}`}</>
                                            : <>{`${props.tooltipLabel}: ${_data.total}`}</>
                                        }
                                        
                                        </p>
                                    </div>
                                }
                            >
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                        height: barGraphHeight,
                                        width: barWidth
                                }}>
                                    <div
                                        key={i}
                                        style={{                    
                                            borderRadius: '1.99766px 1.99766px 0px 0px',
                                            width: barWidth,
                                            height: _data.total > 0 ? calculateBarHeight(_data.total) + 1 : 1,
                                            transition: '0.5s',
                                            backgroundColor: _data.total > 0 ? color: '#000000',
                                        }}
                                        />
                                </div>
                            </Tooltip>
                        ))
                    }
            </div>
            <div style={styles.labelsWrapper}>
                {
                    labels.map((label, i) => (
                        <p 
                            key={i}
                            style={styles.labels} 
                            className="Font-Rubik"
                        >
                            {label}
                        </p>
                    ))
                }
            </div>
        </div>
    )
}

const styles: StyleSheet = {
    container: {
        display: 'flex',
        gap: 1,
    },
    labelsWrapper: {
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        height: 21,
        width: '100%',
    },
    labels: {
        width: '100%',
        fontWeight: 400,
        fontSize: 12,
        textAlign: 'center'
    }
}