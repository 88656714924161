import React, { useEffect, useState } from 'react';
import Chart from "react-apexcharts";
import { colors } from '../../../assets/styles';
import { useAppSelector } from '../../../redux/hooks';
import { sortTimeSeriesData } from '../../../redux/reports/reportsHelpers';

export const TimeSeriesChart: React.FC = () => {
    const reports = useAppSelector(state => state.reports.reportsByRange)
    const [tsData, setTsData] = useState<any[]>([])

    useEffect(() => {
        setTsData(sortTimeSeriesData(reports.data))
    },[reports])

    type datetime = "datetime";
    var dateTime : datetime = "datetime"
    
    interface opts {
        series: any,
        seriesIndex: any,
        dataPointIndex: any,
        w: any
    }
    
    const yValues = ["Normal", "Low", "Medium", "High"]
    
    var divStyle= `
    background-color: white;
    padding: 0.5em;
    `
    var options = {
        colors:[colors.primary],
        stroke: {
            width: 2,
        },
        tooltip: {
            custom: function(opts: opts) {
                let anxietyLevel = opts.series[opts.seriesIndex][opts.dataPointIndex]
                return (
                    `<div style='${divStyle}'>
                    <span>
                    Strong Emotion: ${yValues[anxietyLevel]}
                    </span>
                    </div>`
                    )
                }
            },
            xaxis: {
                type: dateTime,
                labels: {
                    datetimeUTC: false
                },
                line: {
                    show: true
                },
                tickPlacement: 'on',
                tickAmount: 100,
                stroke: {
                    curve: 'stepline'
                },
            },
            yaxis: {
                max: 3,
                tickAmount: 3,
                labels: {
                    show: true,
                    formatter: (value: any) => { return yValues[value]}
                }
            },
            chart: {
                dataLabels: {
                    enabled: false
                },
                toolbar: {
                    tools: {
                        download: false
                    }
                },
            }
        }
        
        var series = [{
            name: "Anxiety Level",
            data: tsData
        }]
        
        return (
            <>
            {
                reports.data.length > 0 &&
                <Chart 
                type="line"
                width="95%"
                height="400px"
                sx={{

                }}
                series={series}
                options={options}
                />
            }
        </>
    )
}
