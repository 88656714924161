import { CircularProgress, Tab, Tabs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useChartTypes, useNotifications, useParticipants, useReports, useReportsByRange, useSelectedParticipant, } from "../../../redux/hooks";
import { TimeSeriesChart } from "../TimeSeries/TimeSeries";
import { HeatMap } from "../HeatMap/HeatMap";
import { colors } from "../../../assets/styles";
import { setChartType } from "../../../redux/components/componentsHelpers";
import { ChartTypes } from "../../../redux/components/interfaces";
import { NotificationsTable } from "../../Tables/NotificationsTable";
import { AnalyticsTag, AnalyticsEventName, ClickParams} from "../../../analytics";
import { CreateAnalyticsEvent } from "../../../redux/analytics/analyticsHelpers";
import { Loading } from "../../Loading/Loading";

export const ChartTabs: React.FC = () => {
    const reportData = useReportsByRange()
    const { pending: reportsPending } = useReports().reportsByRange
    const chartType = useChartTypes().selected
    const { data, pending: participantPending } = useSelectedParticipant()
    const {data: notifications, pending: notificationsPending} = useNotifications()
    const [value, setValue] = useState(1);
    const clickEvent = CreateAnalyticsEvent<ClickParams>(AnalyticsEventName.DASHBOARD_GRAPHS, AnalyticsTag.CLICK)

    const handleChange = (_: React.SyntheticEvent, newValue: number) => {
        newValue === 0 && clickEvent.Save({params: {page: 'dashboard', button: 'time_series'}})
        newValue === 1 && clickEvent.Save({params: {page: 'dashboard', button: 'heat_map'}})
        setValue(newValue);
        setChartType(ChartTypes[newValue])
    };

    if(chartType !== ChartTypes[value]) {
        setChartType(ChartTypes[value])
    }

    if(participantPending) return <Loading message="Loading participant..." />
    if(!data) return <></>
    return (
        <>
        <div
        style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        }}>
            <Tabs 
                defaultValue={1}
                value={value} 
                onChange={handleChange} 
                aria-label="Report charts tab selection"
                textColor={"primary"}
            >
                <Tab 
                    label="Time Series" 
                    sx={{color: colors.primary}}/>
                <Tab 
                    label="Heat Map"
                    sx={{color: colors.primary}}
                />
            </Tabs>
            <br />
        </div>
        {
            (reportData.length > 0 && !reportsPending) && <div>
                {
                    value === 0 && <TimeSeriesChart />
                }
                {
                    value === 1 && <HeatMap />
                }
                {
                    !notificationsPending &&
                    <NotificationsTable selectCallBack={() => {}}/>
                }
            </div>
        }
        {
            reportsPending && 
            <div
            style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                alignItems: 'center',
                justifyContent: 'center'
            }}
            >
                <CircularProgress/>
            </div>
        }
        {
            (reportData.length === 0 && !reportsPending) && 
            <div style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                padding: '2em 0'
            }}>
                <p>No data available for that time range</p>
            </div>
        }
        {/*  */}
        </>
    )
}