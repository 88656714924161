import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getHeaders } from "./pairingHelpers";
import { DB_ENDPOINT } from "../db";

const PATH = DB_ENDPOINT + "/pairing/v3";
// const PATH = "http://localhost:3335/pairing/v3";

export enum PairingStatus {
    "NUll" = 0,
    "In Progress" = 1,
    "Verified" = 2,
    "Success" = 3,
    "Timed Out" = 4,
    "Unpaired" = 5,
}

export type Pairing = {
    wearable_identifier: string;
    participant_id: string;
    pairing_status: number;
    pairing_pin: string;
    created_at: Date;
    updated_at: Date;
};

type PairingArgs = {
    pin: string;
    participant_id: string;
};

export const fetchAllPairings = createAsyncThunk(
    "pairing/getAllPairings",
    async () => {
        return fetch(PATH, {
            method: "GET",
            headers: getHeaders(),
        }).then((res) => res.json());
    }
);

export const verifyPairing = createAsyncThunk(
    "pairing/verifyPairing",
    async (args: PairingArgs) => {
        return fetch(PATH + "/pin/verify", {
            method: "POST",
            headers: getHeaders(),
            body: JSON.stringify(args),
        }).then((res) => res.json());
    }
);

const initialState: {
    pending: boolean;
    lastPaired: Pairing | undefined;
    pairings: Pairing[];
    error: string | undefined;
} = {
    pending: false,
    lastPaired: undefined,
    pairings: [],
    error: undefined,
};

export const pairingSlice = createSlice({
    name: "pairing",
    initialState,
    reducers: {
        setLastPaired: (state, action) => {
            state.lastPaired = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            // VERIFY PAIRING
            .addCase(verifyPairing.pending, (state) => {
                state.error = undefined;
                state.pending = true;
            })
            .addCase(verifyPairing.fulfilled, (state, action) => {
                if (!action.payload.pin) {
                    state.error = action.payload.message;
                    return;
                }
                state.pending = false;
            })
            .addCase(verifyPairing.rejected, (state) => {
                state.pending = false;
            })
            // GET ALL PAIRINGS
            .addCase(fetchAllPairings.pending, (state) => {
                state.pending = true;
                state.error = undefined;
            })
            .addCase(fetchAllPairings.fulfilled, (state, action) => {
                state.pairings = action.payload.sort((a: Pairing, b: Pairing) =>
                    a.updated_at > b.updated_at ? -1 : 1
                );
                state.pending = false;
            })
            .addCase(fetchAllPairings.rejected, (state) => {
                state.error = "Failed to fetch pairings";
                state.pending = false;
            });
    },
});

export const { setLastPaired } = pairingSlice.actions;

export default pairingSlice.reducer;
