import { useCallback, useEffect, useState } from "react";
import { StyleSheet } from "../../assets/styles";
import { Participant } from "../../interfaces/Participant";
import { Roles, useCareTeams, useParticipants, useRoles } from "../../redux/hooks";
import { getParticipantsByCareTeamId, selectParticipant } from "../../redux/participants/participantsHelpers";
import { ComboBox, Options } from "./ComboBox";

interface Props {
    callback: (id: string) => void;
    defaultId?: string;
    disabled?: boolean;
    style?: StyleSheet
}

export const ParticipantSelection: React.FC<Props> = ({callback, defaultId, disabled, style}) => {
    const { selectedCareTeam } = useCareTeams();
    const { allParticipants, selectedParticipant } = useParticipants();
    const [careTeamId, setCareTeamId] = useState<null | string>(null);
    const [careTeamAccess] = useRoles(Roles.careteam);
    const [dropDownOptions, setDropDownOptions] = useState([{label: "None", value: "none"}]);

    const defaultValue = useCallback(() => {
        if(selectedParticipant.data && defaultId) {
            return {
                label: selectedParticipant.data.description,
                value: selectedParticipant.data.id
            }
        }
        return null;
    },[selectedParticipant, defaultId])

    useEffect(() => {
        if(selectedCareTeam.data.length > 0) {
            setCareTeamId(selectedCareTeam.data[0].id)
        }
    },[selectedCareTeam.data])

    useEffect(() => {
        careTeamAccess && careTeamId && getParticipantsByCareTeamId(careTeamId)
    },[careTeamAccess, careTeamId])

    // Create options for drop down list
    useEffect(() => {
            if(allParticipants.data) {
                let options = allParticipants.data.reduce((acc: any[], participant: Participant) => {
                        acc.push({
                            label: participant.description,
                            value: participant.id
                        })
                        return acc
                    },[])
            // If defaultId provided, make default selection
                if(defaultId) {
                    selectParticipant(defaultId, false)
                }

                setDropDownOptions(options)
            }
    },[allParticipants.data, defaultId])

    const selectDropDown = (selection: Options | null) => {
        if(selection?.value) {
            callback(selection.value);
            selectParticipant(selection.value, false);
        }
    }

    return (
        <>
        {
            <div style={{...style}}> 
                <ComboBox 
                    label={'Participant'} 
                    options={dropDownOptions} 
                    callback={selectDropDown}
                    defaultValue={defaultValue()}
                    disabled={disabled}
                />
            </div>
        }
        </>
    )
}