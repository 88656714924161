export const passwordWarning = "Passwords must be at least 8 characters in length and contain upper and lower case letters."
export const passwordsNotEqual = "The passwords you have entered do not match."
export const macAddresssWarning = "Watch Identifier must be in the format XX:XX:XX:XX:XX:XX using uppercase alphanumeric characters."
export const emailWarning = "Please enter a valid email address."
export const requiredFieldsWarning = "Please fill out the required fields."
export const participantsWarning = "Please select at least one participant."

export const validatePassword = (password: string) => {
    const characters = [...password]
    let charCount = 0
    let upperCaseCount = 0
    let lowerCaseCount = 0
    
    function containsSpecialChars(str: string) {
        const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        return specialChars.test(str);
    }

    function is_numeric(str: string){
        return /^\d+$/.test(str);
    }

    for(let i=0; i < characters.length; i++) {
        charCount += 1;
        if(
            characters[i].toUpperCase() === characters[i] 
            && !is_numeric(characters[i])
            && !containsSpecialChars(characters[i])
            ) {
            upperCaseCount += 1;
        }
        if(characters[i].toLowerCase() === characters[i])
        lowerCaseCount += 1;
    }

    if(charCount >= 8 && upperCaseCount >= 1 && lowerCaseCount >= 1)
    return true;

    return false;
}

export const validatePasswordsEqual = (password: string, repeatPassword: string) => {
    if(password === "" || repeatPassword === "") return false;
    return password === repeatPassword;
}

export const validateMacAddress = (mac_address: string) => {

    // Split string by : delimiter
    let colon_split = mac_address.split(':')
    // Check that each member of the split contains two characters
    let pairs = colon_split.reduce((acc, item) => {
        if(item.length === 2) acc += 1
        return acc;
    },0)

    // Check umber of colons
    let colons = mac_address.split(":").length - 1

    // Check that each alphabetic character is capitalized
    const chars = colon_split.join('');
    let individual_chars = [...chars];
    let all_caps: boolean = true;
    individual_chars.forEach(char => {
        if(isNaN(parseInt(char))) {
            let upper = char.toUpperCase();
            if(upper !== char)
            all_caps = false;
            return all_caps;
        }
    })

    if(pairs === 6 && colons === 5 && chars.length === 12 && all_caps)
    return true;
    else
    return false;
}


export const validateRequiredFields = (fields: string[], callBack?: Function) => {
    for(let i = 0; i < fields.length; i++) {
        if(fields[i].length === 0) return false;
    }
    return true;
}

export const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
};

export const validateIsEmployeeEmail = (email: string) => {
    return email.includes("@awakelabs.com")
}