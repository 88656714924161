import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useEffect, useState } from 'react';

export interface Options {
  label: string,
  value: string
}

interface Props {
  callback: (value: Options | null) => void,
  label: string,
  options: Options[],
  defaultValue?: Options | null,
  disabled?: boolean
}

export const ComboBox: React.FC<Props> = (props: Props) => {

  const { callback, options, label, defaultValue, disabled } = props

  const [_options, setOptions] = useState(options)
  const [selection, setSelection] = useState<Options>(
    defaultValue ? defaultValue : {label: label, value: "None"}
    );

  useEffect(() => {
    setOptions(options)
    setSelection({label: label, value: "None"})
  },[options, label])

  useEffect(() => {
    if(defaultValue)
    setSelection(defaultValue)
  }, [defaultValue])

  const handleChange = (selection: Options | null) => {
    if(!selection) return;
    setSelection(selection);
    callback(selection);
  }

  return (
    <Autocomplete
      onChange={(event: any, newValue: Options | null) => handleChange(newValue)}
      disablePortal
      value={selection}
      id="combo-box"
      options={_options}
      sx={{ width: 299 }}
      color="primary"
      disabled={disabled}
      renderInput={(params) => 
      <TextField
        key={params.id} 
        {...params} 
        label={label}
      />}
    />
  );
}
