import { Button } from "@mui/material"
import { useEffect, useState } from "react"
import { BasicModal } from "./BasicModal"

interface AcceptModalProps {
    callBack?: Function,
    message: string,
    isOpen: boolean
}

export const AcceptModal: React.FC<AcceptModalProps> = ({message, isOpen, callBack}) => {

    const [open, setOpen] = useState(false)

    useEffect(() => {
        setOpen(isOpen)
    }, [isOpen])

    const handleAccept = () => {
        setOpen(false)
        callBack && callBack()
    }

    const modalContent = 
        <div style={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            flexDirection: 'column',
        }}>
            <p>{message}</p><br/>
            <div>
                <Button 
                    variant="contained" 
                    onClick={handleAccept}
                >
                    OK
                </Button>
            </div>
        </div>
 
    return (
        <>
            <BasicModal
            open={open}
            callback={callBack}
            >
                <p style={{textAlign: 'center'}}>
                    {modalContent}
                </p>
            </BasicModal>
        </>
    )
}