import { Tooltip } from "@mui/material";
import React from "react";
import { useHeatMapData } from "../../../redux/hooks";
import InfoIcon from '@mui/icons-material/Info';
import './HeatMap.css'


enum colors {
    '#A30000' = 6,
    '#ED351D' = 5,
    '#F26418' = 4,
    '#FEE100' = 3,
    '#85B83D' = 2,
    '#3B840B' = 1,
    '#224B06' = 0,
}

enum months {
    "Jan" = 0,
    "Feb" = 1,
    "Mar" = 2,
    "Apr" = 3,
    "May" = 4,
    "Jun" = 5,
    "Jul" = 6,
    "Aug" = 7,
    "Sept" = 8,
    "Oct" = 9,
    "Nov" = 10,
    "Dec" = 11
}

enum days {
    "Sun" = 0,
    "Mon" = 1,
    "Tue" = 2,
    "Wed" = 3,
    "Thu" = 4,
    "Fri" = 5,
    "Sat" = 6
}

const makeReadableDate = (timestamp: number) => {
    let date = new Date(timestamp)
    return `${months[date.getMonth()]} ${date.getDate()}`
}

const makeReadableToolTipsDate = (timestamp: number) => {
    let date = new Date(timestamp)
    return `${days[date.getDay()]} ${months[date.getMonth()]} ${date.getDate()}`
    
}

interface XAxisBlockProps {
    date: number
    data: number
    index: number
}

const XAxisItemBlock: React.FC<XAxisBlockProps> = (Props: XAxisBlockProps) => {

    const blockStyle = {
        width: 'auto',
        height: 'auto'
    }

    var value = Props.data !== -1 ? Props.data : null

    var styles = {
        backgroundColor: value !== null ? colors[Math.ceil(Props.data/(100/6))] : '#F5F5F5'
    }

    var toolTipDate = makeReadableToolTipsDate(Props.date)

    return (
        <div 
            className="heatmap-x-axis-item-block-container"
            style={blockStyle}
        >
            {
                value !== null &&
                <Tooltip 
                    placement="right"
                    arrow={true}
                    title={
                        <div style={{
                            padding: '0.5em',
                        }}>
                            <p style={{
                                fontSize: 16
                            }}>
                                <b>{toolTipDate}, {Props.index}:00</b><br />
                                Strong Emotion: {value}% 
                            </p>
                        </div>
                    }
                >
                <div className="heatmap-x-axis-item-block-inner" style={styles}>
                    <br/>
                </div>
                </Tooltip>
            }
            {
                value === null &&
                <div className="heatmap-x-axis-item-block-inner" style={styles}>
                    <br></br>
                </div>
            }
        </div>
    )
}

interface XAxisItemProps {
    date: number,
    data: Map<number, number>
}

const XAxisItemContainer: React.FC<XAxisItemProps> = (Props: XAxisItemProps) => {
    var date = makeReadableDate(Props.date)
    return (
        <div className="heatmap-x-axis-item">
            {[...Props.data.keys()].map((item, i) => (
                <XAxisItemBlock key={i} data={Props.data.get(item)!} date={Props.date} index={i}/>
                ))}
            <div className="heatmap-x-axis-item-label">
                <p style={{
                    position: 'relative',
                    right: '1em',
                    marginTop: '1.75em',
                    zIndex: 100,
                    transform: 'rotate(-55deg)',
                    width: '4em',
                    textAlign: 'right'
                }}>{date}</p>
            </div>
        </div>
    )
}

interface YAxisLabelProps {
    labels: (string | number)[]
}

const YAxisLabel: React.FC<YAxisLabelProps> = (Props: YAxisLabelProps) => {

    return (
        <div className="heatmap-y-axis-labels-container">
        {Props.labels.map((label, i) => (
            <div key={i} className="heatmap-y-axis-label-container">{label}</div>
        ))}
        </div>
    )
}


export const HeatMap: React.FC = () => {
    const data = useHeatMapData()

    var labels = [
        "0:00", "1:00", "2:00", "3:00", "4:00", "5:00", "6:00", "7:00"
        , "8:00", "9:00", "10:00", "11:00", "12:00", "13:00", "14:00", "15:00"
        , "16:00", "17:00", "18:00", "19:00", "20:00", "21:00", "22:00", "23:00"]

    return (
        <>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%'
            }}>
                {
                    (data) &&
                    <>
                        <div className="heatmap-wrapper">
                            <div className="time-legend"><p>Time of Day</p></div>
                            <div className="ylabels-map">
                                <YAxisLabel labels={labels}/>
                                <div className="heatmap-container">
                                    {
                                        [...data.keys()].map((timestamp, i) => (
                                            <XAxisItemContainer data={data.get(timestamp)!} date={timestamp} key={i}/>
                                        ))
                                    }
                                </div>
                            </div>
                            <div className="date-legend"><p>Date</p></div>
                        </div>
                    </>
                    
                }
            </div>
            {
            (data) &&
                <GradientLegend />
            }
        </>
    )
}

const GradientLegend: React.FC = () => {

    return (
        <div className="gradient-legend-wrapper">
            <div className="gradient-legend">
                <div className="gradient-legend-label left"><p>0%</p></div>
                <div className="gradient-legend-label right"><p>100%</p></div>
            </div>
        </div>
    )
}

export const ToolTipLegend: React.FC = () => {
    const toolTipInfo = (
        <div className="tooltip-info">
            <p>The heatmap displays the percentage of time spent in a state of strong emotion for each hour. <br /><br />
            For example if you see a dark green block, this means that the person wearing the watch experienced no 
            strong emotions for the duration of that hour. A bright red block indicates that the person wearing the watch 
            spent the entirety of the hour in a state of strong emotion.<br /><br />
            Please note that strong emotions include both positive emotions such as excitement and joy, as well as negative 
            emotions such as stress, anger or anxiety.</p>
        </div>
    )
    return (
        <div className="gradient-legend-info">
            <p>Information on how to read the Heat Map</p>
            <Tooltip title={toolTipInfo} arrow={true}>
                <InfoIcon color="primary"/>
            </Tooltip>
        </div>
    )
}