import React, { useEffect, useMemo, useState } from "react";
import { StyleSheet } from "../../assets/styles";
import { ParticipantSelection } from "../../components/ComboBox/ParticipantSelection";
import { BasicStatsCard, NotificationCard } from "../../components/NotificationCard/NotificationCard";
import { Roles, useParticipants, useRoles, useUser, useVisualizations } from "../../redux/hooks";
import { getParticipantsByUserId, resetSelectedParticipant } from "../../redux/participants/participantsHelpers";
import { ComboBox, Options } from "../../components/ComboBox/ComboBox";
import { getAllResponses } from "../../redux/dataVisualizations/dataVisualizationsHelpers";
import { calculateMaxTotals, calculateTotalsFromEmotion, hourlyLabels } from "./utils";
import { Loading } from "../../components/Loading/Loading";
import { useWindowDimensions } from '../../hooks/hooks'
import { max } from "lodash";
import { DatePickerSingleExactTime } from "../../components/Dates/DatePickerSingleExactTime";
import { ManagerParticipantSelection } from "../../components/ComboBox/ManagerParticipantSelection";
import { getUsersByOrganizationId } from "../../redux/users/usersHelpers";
import { ManagerUserSelection } from "../../components/ComboBox/ManagerUserSelection";
import { AnalyticsEventName, ClickParams, DateParams, SelectionParams } from "../../analytics";
import { AnalyticsTag } from "../../analytics";
import { CreateAnalyticsEvent } from "../../redux/analytics/analyticsHelpers";

export const Insights: React.FC = () => {
    //hooks
    const windowWidth = useWindowDimensions().width
    const [careTeamAccess] = useRoles(Roles.careteam)
    const selectedParticipant = useParticipants().selectedParticipant.data
    const visualizationData = useVisualizations().allResponses.data
    const loading = useVisualizations().allResponses.pending
    const { user_id, organization_id } = useUser();
    //state
    const [participantId, setParticipantId] = useState<string>();
    const [userId, setUserId] = useState<string>();
    const [emotion, setEmotion] = useState<string | undefined>('total');
    const [startAt, setStartAt] = useState<number | undefined>();
    const [endAt, setEndAt] = useState<number | undefined>(new Date().getTime());
    const [enrollmentDate, setEnrollmentDate] = useState<number>();
    const [maxBarValue, setMaxBarValue] = useState<number>(0);
    // Analytics
    const clickEvent = CreateAnalyticsEvent<ClickParams & DateParams>(AnalyticsEventName.INSIGHTS, AnalyticsTag.CLICK)

    useEffect(() => {
        if(!(participantId && userId && startAt && endAt)) return
        getAllResponses(participantId, userId, startAt, endAt)
    },[participantId, userId, startAt, endAt])
    
    // Clear selected participant so that participant selection combo box is empty on page load
    useEffect(() => {
        resetSelectedParticipant()
        // If user is not a manager, user's id is used to fetch data
        if(!careTeamAccess && user_id) {
            setUserId(user_id)
        }
    },[careTeamAccess, user_id])
    
    useEffect(() => {
        // If user is not a manager, fetch limited participants to populate participant selection combo box
        if(user_id && !careTeamAccess)
        getParticipantsByUserId(user_id);

        // If user is a manager, fetch users by organization id to populate user selection combo box
        if(user_id && careTeamAccess && organization_id)
        getUsersByOrganizationId(organization_id)
    },[user_id, careTeamAccess, organization_id]);
    
    // Set default startAt to participant enrollment date
    useEffect(() => {
        if(
            !selectedParticipant || 
            !selectedParticipant.created_at ||
            !(selectedParticipant.id === participantId)
        ) return;

        const createdAt = selectedParticipant.created_at
        // Parse created at string to unix time
        const unixTime = new Date(createdAt).getTime()
        setEnrollmentDate(unixTime)
        setStartAt(unixTime)
        setEndAt(new Date().getTime())
    },[selectedParticipant, participantId])
    
    useEffect(() => {
        // Calculate maximum 'total' value for each bar graph
        const notes = calculateMaxTotals(visualizationData[0].monthly)
        const monthly = calculateMaxTotals(visualizationData[0].monthly)
        const daily = calculateMaxTotals(visualizationData[0].daily)
        const hourly = calculateMaxTotals(visualizationData[0].hourly)
        const maxTotal = max([notes, monthly, daily, hourly])

        setMaxBarValue(maxTotal ? maxTotal : 0)
    },[visualizationData])

    function handleUserSelection(id: string) {
        resetSelectedParticipant()
        setParticipantId(undefined)
        setStartAt(undefined)
        setEndAt(undefined)
        getParticipantsByUserId(id)
        setUserId(id)
    }

    const options: Options[] = useMemo(() => [
        {
            label: 'All',
            value: 'total'
        },
        {
            label: 'Happy',
            value: 'happy'
        },
        {
            label: 'Anxious',
            value: 'anxious'
        },
        {
            label: 'Other',
            value: 'other'
        }
    ],[])

    function handleClickEvent(params: ClickParams & DateParams & SelectionParams){
        clickEvent.Save({params: {...params}})
    }
        
        return (
            <>
            <ManagerUserSelection 
                callback={(id: string) => {
                    handleClickEvent({page: 'insights', button: "select_user", key: 'userId', value: id})
                    handleUserSelection(id)
                }}
                style={{paddingTop: 5, paddingBottom: 24} as StyleSheet}
            />
            <div style={styles.container}>
                <div style={styles.pair}>
                    {
                        // If user is not a manager, give them access to participants associated with account
                        !careTeamAccess &&
                       <ParticipantSelection 
                            callback={(id: string) => {
                                handleClickEvent({page: 'insights', button: "select_participant", key: 'participantId', value: id}) 
                                setParticipantId(id)
                            }}
                        /> 
                    }
                    {/* Only managers will be able to see this element by default */}
                    <ManagerParticipantSelection 
                        callback={(id: string) => {
                            handleClickEvent({page: 'insights', button: "select_participant", key: 'participantId', value: id})
                            setParticipantId(id)
                        }}
                        disabled={userId ? false : true}
                    />
                    <ComboBox
                        callback={(option: Options | null) => {
                            handleClickEvent({page: 'insights', button: "select_emotion", key: 'emotion', value: option?.value as string})
                            setEmotion(option?.value)
                        }} 
                        label={'Emotion'}
                        options={options}
                        disabled={participantId ? false : true}
                    />
                </div>
                <div style={styles.pair}>
                    <DatePickerSingleExactTime
                        selectCallBack={(from: number) => {
                            handleClickEvent({page: 'insights', button: "select_start_date", key: 'from', value: from})
                            setStartAt(from)
                        }}
                        disabled={participantId ? false : true}
                        value={startAt}
                        defaultValue={enrollmentDate}
                        minDateUnix={enrollmentDate}
                        maxDateUnix={endAt}
                        label={"Start Date"}
                        style={{width: 300}}
                    />
                    <DatePickerSingleExactTime 
                        selectCallBack={(_, to: number) => {
                            handleClickEvent({page: 'insights', button: "select_start_date", key: 'to', value: to})
                            setEndAt(to)
                        }}
                        disabled={startAt ? false : true}
                        value={endAt}
                        minDateUnix={startAt}
                        maxDateUnix={new Date().getTime()}
                        label={"End Date"}
                        style={{width: 299}}
                    />
                </div>
                <br />
                <br />
                {
                    visualizationData && 
                    visualizationData[0].basic &&
                    visualizationData[0].basic[0]?.numberOfDaysWorn === 0 &&
                    participantId &&
                    !loading &&
                    <div style={{
                        ...styles.flexContentWrapper,
                        width: windowWidth >= 1500 ? '100%' : 622
                    }}>
                        <p>No data to display for this selected time period.</p>
                    </div>
                }
                {
                    loading ?
                    <div style={{
                        ...styles.flexContentWrapper,
                        width: windowWidth >= 1500 ? '100%' : 622
                    }}>
                        <Loading message={"Loading Data..."}/>
                    </div>
                    : selectedParticipant &&
                    <div style={{
                        ...styles.cardsWrapper,
                        width: windowWidth >= 1500 ? '100%' : 700
                    }}>

                        <div style={styles.pair}>
                                {                
                                    (visualizationData[0]?.basic[0]?.numberOfDaysWorn > 0) &&
                                    <BasicStatsCard
                                        data={visualizationData[0].basic}
                                        style={{width: 260} as StyleSheet}
                                    /> 
                                }
                                {                                
                                    visualizationData && visualizationData[0]?.daily.length > 0 &&
                                    <NotificationCard
                                        title={`${emotion === 'total'?'Notifications':' Notes created'} by day of the week`}
                                        subtext={`Helps you answer: which day of the week did we get the most and the fewest ${emotion === 'total'? 'notifications' : 'responses'}?`}
                                        barChartProps={{
                                            maxValue: maxBarValue,
                                            color: '#F55457',
                                            barWidth: 221/7,
                                            data: calculateTotalsFromEmotion(visualizationData[0].daily, emotion),
                                            labels: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
                                        }}
                                    />
                                }
                                </div>
                            
                            <div style={styles.pair}>
                                {
                                    visualizationData && visualizationData[0]?.hourly.length > 0 &&
                                        <NotificationCard 
                                            title={`${emotion === 'total'?'Notifications':' Notes created'} by hour of the day`}
                                            subtext={`Helps you answer: what time of the day do we typically get the most ${emotion === 'total'? 'notifications' : 'responses'}?`}
                                            style={{width: 583}}
                                            barChartProps={{
                                                maxValue: maxBarValue,
                                                color: '#F55457',
                                                barWidth: 221/5.65,
                                                data: calculateTotalsFromEmotion(visualizationData[0].hourly, emotion),
                                                labels: hourlyLabels(visualizationData[0].hourly)
                                            }}
                                        />
                                }
                            </div>
                            <div style={styles.pair}>
                                {
                                    visualizationData && 
                                    visualizationData[0]?.monthly.length > 0 &&
                                    emotion === 'total' &&
                                        <NotificationCard 
                                            title={`Months with most ${emotion === 'total'?'notifications':'notes'}`}
                                            subtext={`Helps you answer: which month did you receive the most ${emotion === 'total'?'notifications':'notes'}?`}
                                            barChartProps={{
                                                maxValue: maxBarValue,
                                                tooltipPrefix: `Notifications received in`,
                                                color: '#5CA157',
                                                barWidth: 18,
                                                data: calculateTotalsFromEmotion(visualizationData[0].monthly, emotion),
                                                labels: ['Feb', 'Apr', 'Jun', 'Aug', 'Oct', 'Dec']
                                            }}
                                        />
                                }
                                {
                                    visualizationData && 
                                    visualizationData[0]?.monthly.length > 0 &&
                                        <NotificationCard 
                                        title="Months with highest notification responses"
                                        subtext={`Helps you answer: which month did you engage with the most notifications?`}
                                        barChartProps={{
                                            maxValue: maxBarValue,
                                            tooltipPrefix: 'Notes created in',
                                            color: '#E96524',
                                            barWidth: 18,
                                            data: calculateTotalsFromEmotion(visualizationData[0].monthly, emotion, true),
                                            labels: ['Feb', 'Apr', 'Jun', 'Aug', 'Oct', 'Dec']
                                        }}
                                    />
                                }
                            </div>        
                    </div>
                }
            </div>
        </>
    )
}

const styles: StyleSheet = {
    container: {
        display: 'inline-flex',
        width: '100%',
        flexWrap: 'wrap',
        gap: 24,
        maxWidth: 1500
    },
    selection: {
        display: 'flex',
        gap: 24,
        marginBottom: 24,
        flexWrap: 'wrap'
    },
    cardsWrapper: {
        display: 'inline-flex',
        flexWrap: 'wrap',
        gap: 30
    },
    pair: {
        display: 'inline-flex',
        flexWrap: 'wrap',
        gap: 24,
    },
    cards: {
        display: 'inline-flex',
        flexWrap: 'wrap',
        gap:24
    },
    flexContentWrapper: {
        display: 'flex',
        justifyContent: 'center',
    }
}