import React from 'react'
import './Buttons.css'
import '../../assets/Fonts/fonts.css'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useScrollPosition } from '../../hooks/hooks';
import { useHistory } from 'react-router-dom';

export const MenuButton: React.FC = ({children}) => {

    return (
        <button
            className="button__transparent button__menu"
        >
            <div className="button__content">
                {children}
            </div>
        </button>
    )
}

export const ScrollTopButton: React.FC = () => {
    
    const scrollPosition = useScrollPosition()

    function handleClick() {
        if(scrollPosition > 0)
        window.scrollTo(0,0)
    }

    return (
        <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'fixed',
            bottom: 20,
            right: 20,
            fontSize: 20,
            boxShadow: '0px 0.5px 1.75px rgba(0, 0, 0, 0.039), 0px 1.85px 6.25px rgba(0, 0, 0, 0.19)',
            borderRadius: '100%',
            backgroundColor: scrollPosition === 0 ? '#E9E9E9' : '#3B3B3B',
            padding: 10
        }}>
            <ArrowUpwardIcon 
                style={{
                    fontSize: 30,
                    color: scrollPosition === 0 ? '#959595': '#FFFFFF',
                    cursor: scrollPosition === 0 ? 'unset' : 'pointer'
                }}
                onClick={() => handleClick()}
            />
        </div>
    )
}

export const GoBackButton: React.FC = () => {

    const history = useHistory()

    return (
        <div 
            style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingTop: 5,
                    paddingRight: 10,
                    paddingBottom: 5,
                    paddingLeft: 5,
                    borderRadius: 16,
                    boxShadow: '0px 0.5px 1.75px rgba(0, 0, 0, 0.039), 0px 1.85px 6.25px rgba(0, 0, 0, 0.19)',
                    cursor: 'pointer',
                    backgroundColor: '#3B3B3B',
                    color: 'white'
                }}
            className={'Font-Rubrik'}
            onClick={() => history.goBack()}
        >
            <ArrowBackIcon 
                style={{
                    fontSize: 30,
                    marginRight: 5
                }}
                />
            <p
                style={{
                    fontWeight: 500
                }}
            >
                Go Back
            </p>
        </div>
    )
}